import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Copyright from "../components/generic/Copyright";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { SignInForm } from "../components/sign_in/SignInForm";
import Typography from "@mui/material/Typography";

const SignIn = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/logo.svg"
          alt="Logo"
          style={{
            width: "20%",
            height: "20%",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        />
        <Typography variant="h4" color="primary.main">
          Beyond Reality
        </Typography>
        <Typography variant="subtitle2">
          Become the best version of yourself
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          paddingTop={4}
          sx={{ color: "primary.main" }}
        >
          Sign In
        </Typography>
        <SignInForm />
      </Box>
      <Typography variant="subtitle2" align="center">
        Don't have an account?{" "}
        <Link component={RouterLink} to={`/${Page.SIGN_UP}`}>
          Sign Up
        </Link>
      </Typography>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default SignIn;
