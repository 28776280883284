export const formatGoalProgress = (
  target: string,
  current: string,
  kpi: string
): string => {
  let goalProgress = "";
  
  if (target && current && kpi) {
    goalProgress = `${current} / ${target} ${kpi}`;
  }
  if (target && current && !kpi) {
    goalProgress = `${current} / ${target}`;
  }
  if (target && !current && !kpi) {
    goalProgress = `${target}`;
  }

  return goalProgress;
};
