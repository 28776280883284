import CardButton from "../../layout/CardButton";

type NewReviewButtonProps = {
  title: string;
  description: string;
  loading: boolean;
  onClick: () => void;
};

const NewReviewButton: React.FC<NewReviewButtonProps> = ({
  title,
  description,
  loading,
  onClick,
}) => {
  return (
    <CardButton
      title={title}
      description={description}
      loading={loading}
      onClick={onClick}
    />
  );
};

export default NewReviewButton;
