import AddThemeCardButton from "../add_theme_card_button/AddThemeCardButton";
import Grid from "@mui/material/Grid";
import LoadingSpinner from "../../layout/LoadingSpinner";
import ThemeCardController from "../theme_card/ThemeCardController";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";

type ThemesListViewProps = {
  themes: ThemeClass[];
  loading: boolean;
  error: boolean;
  errorMessage?: string;
};

const ThemesListView: React.FC<ThemesListViewProps> = ({
  themes,
  loading,
  error,
  errorMessage,
}) => {
  const cardWidth = 250;
  const cardHeight = 250;

  return (
    <>
      {loading && !error && <LoadingSpinner />}
      {error && <div>{errorMessage}</div>}
      {!loading && !error && (
        <Grid container spacing={1} columns={{ xs: 2, sm: 8, md: 12 }}>
          {themes.map((theme) => (
            <Grid
              item
              xs={2}
              sm={4}
              key={theme.id}
              height={cardHeight}
              width={cardWidth}
            >
              <ThemeCardController theme={theme} />
            </Grid>
          ))}
          <Grid item xs={2} sm={4} height={cardHeight} width={cardWidth}>
            <AddThemeCardButton />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ThemesListView;
