import { Auth } from "@firebase/auth";
import { Firestore } from "firebase/firestore";
import {
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { PersonalityTestField } from "@beyondrealityapp/core/personality_test/constants";
import {
  ErrorMessage,
  Role,
  Model,
  Operator,
  Order,
} from "@beyondrealityapp/core/shared/constants";
import { PersonalityTestStatus } from "@beyondrealityapp/core/personality_test/constants";
import {
  PersonalityTestType,
  PersonalityTestForm,
  PersonalityTestTestField,
} from "@beyondrealityapp/core/personality_test/types";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export class PersonalityTestClass {
  id: string;
  name: string;
  description: string;
  link: string;
  testFields: PersonalityTestTestField[];
  testId: string;
  uid: string;
  status: "pending" | "completed" | "deleted" | "template";
  userGenerated: boolean;
  metaData: {
    createdAt: Date | null;
    updatedAt: Date | null;
  };

  constructor(personalityTestForm: PersonalityTestForm) {
    this.id = personalityTestForm.id;
    this.name = personalityTestForm.name;
    this.description = personalityTestForm.description;
    this.link = personalityTestForm.link;
    this.testFields = personalityTestForm.testFields;
    this.testId = personalityTestForm.id;
    this.uid = "";
    this.status = PersonalityTestStatus.PENDING;
    this.userGenerated = true;
    this.metaData = {
      createdAt: null,
      updatedAt: new Date(),
    };
  }

  setIdAndTestIdIfEmpty() {
    if (this.id === "") {
      this.id = uuidv4();
      this.testId = this.id;
    }
  }
  setCreatedAtIfIdEmpty() {
    if (this.id === "") {
      this.metaData.createdAt = new Date();
    }
  }
}

export const doesTestNameExist = (
  uid: string,
  id: string,
  name: String,
  db: Firestore
) => {
  return new Promise<void>((resolve, reject) => {
    const q = query(
      collection(db, Model.PERSONALITY_TESTS),
      and(
        where(PersonalityTestField.UID, Operator.EQUAL_TO, uid),
        where(PersonalityTestField.NAME, Operator.EQUAL_TO, name.trim()),
        where(PersonalityTestField.ID, Operator.NOT_EQUAL_TO, id)
      )
    );
    getDocs(q)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          reject(new Error(ErrorMessage.NAME_ALREADY_EXISTS));
        }
        resolve();
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

export const setStatusBasedOnTestValues = (
  testFields: PersonalityTestTestField[]
) => {
  let status = PersonalityTestStatus.COMPLETED;
  testFields.forEach((testField) => {
    if (testField.value === "") {
      status = PersonalityTestStatus.PENDING;
    }
  });
  return status;
};

export const usePersonalityTests = (db: Firestore, auth: Auth) => {
  const updatePersonalityTestScore = async (
    id: PersonalityTestType["id"],
    testFields: PersonalityTestType["testFields"]
  ) => {
    return new Promise<void>((resolve, reject) => {
      const status = setStatusBasedOnTestValues(testFields);
      updateDoc(doc(db, Model.PERSONALITY_TESTS, id), {
        status,
        testFields,
        metaData: {
          updatedAt: new Date(),
        },
      })
        .then(() => {
          resolve();
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const createUpdatePersonalityTest = async (
    personalityTestForm: PersonalityTestForm,
    userRole: "admin" | "user" | null,
    template?: boolean
  ) => {
    return new Promise<void>((resolve, reject) => {
      const newPersonalityTest = new PersonalityTestClass(personalityTestForm);

      newPersonalityTest.setCreatedAtIfIdEmpty();
      newPersonalityTest.setIdAndTestIdIfEmpty();

      if (template) {
        if (userRole !== Role.ADMIN) {
          reject(new Error(ErrorMessage.NOT_AUTHORIZED));
        }

        newPersonalityTest.uid = "system";
        newPersonalityTest.status = "template";
        newPersonalityTest.userGenerated = false;

        doesTestNameExist(
          newPersonalityTest.uid,
          newPersonalityTest.id,
          newPersonalityTest.name,
          db
        )
          .then(() => {
            const personalityTest: PersonalityTestType = {
              ...newPersonalityTest,
            };

            setDoc(
              doc(db, Model.PERSONALITY_TESTS, newPersonalityTest.id),
              personalityTest
            )
              .then(() => {
                resolve();
              })
              .catch((error: Error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        if (auth.currentUser) {
          newPersonalityTest.uid = auth.currentUser?.uid;
        }

        doesTestNameExist(
          newPersonalityTest.uid,
          newPersonalityTest.id,
          newPersonalityTest.name,
          db
        )
          .then(() => {
            const personalityTest: PersonalityTestType = {
              ...newPersonalityTest,
            };

            setDoc(
              doc(db, Model.PERSONALITY_TESTS, newPersonalityTest.id),
              personalityTest
            )
              .then(() => {
                resolve();
              })
              .catch((error: Error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  const deletePersonalityTest = async (personalityTestId: string) => {
    return new Promise<void>((resolve, reject) => {
      deleteDoc(doc(db, Model.PERSONALITY_TESTS, personalityTestId))
        .then(() => {
          resolve();
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  return {
    deletePersonalityTest,
    updatePersonalityTestScore,
    createUpdatePersonalityTest,
  };
};

export const useFetchPersonalityTests = (db: Firestore, auth: Auth) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [personalityTests, setPersonalityTests] = useState<
    PersonalityTestType[]
  >([]);

  const fetchPersonalityTests = (
    system: boolean = false,
    orderByValue: "name",
    orderDirection: Order
  ) => {
    setLoading(true);
    let uid = auth.currentUser?.uid;

    if (system) {
      uid = "system";
    }

    const q = query(
      collection(db, Model.PERSONALITY_TESTS),
      where(PersonalityTestField.UID, Operator.EQUAL_TO, uid),
      where(
        PersonalityTestField.STATUS,
        Operator.NOT_EQUAL_TO,
        PersonalityTestStatus.DELETED
      ),
      orderBy(PersonalityTestField.STATUS, Order.ASCENDING),
      orderBy(orderByValue, orderDirection)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempArray: PersonalityTestType[] = [];
      querySnapshot.forEach((doc) => {
        const {
          uid,
          testId,
          status,
          userGenerated,
          name,
          description,
          link,
          testFields,
          metaData,
        } = doc.data();

        const tempPersonalityTest = {
          id: doc.id,
          uid,
          testId,
          status,
          userGenerated,
          name,
          description,
          link,
          testFields,
          metaData: {
            createdAt: metaData?.createdAt ? metaData.createdAt.toDate() : null,
            updatedAt: metaData?.updatedAt ? metaData.updatedAt.toDate() : null,
          },
        };
        tempArray.push(tempPersonalityTest);
      });
      setPersonalityTests(tempArray);
      setLoading(false);
    });
    return unsubscribe;
  };

  const fetchPersonalityTest = async (id: string) => {
    setLoading(true);

    let personalityTest = {} as PersonalityTestType;
    const personalityTestRef = doc(db, Model.PERSONALITY_TESTS, id);

    await getDoc(personalityTestRef).then((doc) => {
      if (doc.exists()) {
        const {
          uid,
          testId,
          status,
          userGenerated,
          name,
          description,
          link,
          testFields,
          metaData,
        } = doc.data();

        personalityTest = {
          id: doc.id,
          uid,
          testId,
          status,
          userGenerated,
          name,
          description,
          link,
          testFields,
          metaData: {
            createdAt: metaData?.createdAt ? metaData.createdAt.toDate() : null,
            updatedAt: metaData?.updatedAt ? metaData.updatedAt.toDate() : null,
          },
        };

        setLoading(false);
      }
    });
    return personalityTest;
  };
  return {
    loading,
    personalityTests,
    fetchPersonalityTest,
    fetchPersonalityTests,
  };
};
