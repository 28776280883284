import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import {
  GoalFormType,
  GoalStatusType,
} from "@beyondrealityapp/core/goal/types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

export type GoalCardChipsProps = {
  descriptionOpen: boolean;
  kpiOpen: boolean;
  themesOpen: boolean;
  datesOpen: boolean;
  handleDescriptionToggle: () => void;
  handleKpiToggle: () => void;
  handleThemesToggle: () => void;
  handleDatesToggle: () => void;
  formik: FormikState<Partial<GoalFormType>> &
    FormikHelpers<Partial<GoalFormType>> &
    FormikHandlers;
};

const GoalCardChips: React.FC<GoalCardChipsProps> = ({
  descriptionOpen,
  kpiOpen,
  themesOpen,
  datesOpen,
  handleDescriptionToggle,
  handleKpiToggle,
  handleThemesToggle,
  handleDatesToggle,
  formik,
}) => {
  const statusOptions: GoalStatusType[] = [
    "Not started",
    "In progress",
    "Completed",
    "Overdue",
    "",
  ];
  return (
    <Grid container columnSpacing={1}>
      <Grid item marginTop={1} key={"Description"}>
        <Chip
          label={descriptionOpen ? "Hide description" : "Show description"}
          variant={"outlined"}
          onClick={handleDescriptionToggle}
        />
      </Grid>
      <Grid item marginTop={1} key={"KPI"}>
        <Chip
          label={kpiOpen ? "Hide KPI" : "Show KPI"}
          variant={"outlined"}
          onClick={handleKpiToggle}
        />
      </Grid>
      <Grid item marginTop={1} key={"Themes"}>
        <Chip
          label={themesOpen ? "Hide themes" : "Show themes"}
          variant={"outlined"}
          onClick={handleThemesToggle}
        />
      </Grid>
      <Grid item marginTop={1} key={"Dates"}>
        <Chip
          label={datesOpen ? "Hide dates" : "Show dates"}
          variant={"outlined"}
          onClick={handleDatesToggle}
        />
      </Grid>
      <Grid item marginTop={1} key={"Status"}>
        <Select
          value={formik.values.status}
          autoWidth
          sx={{
            height: "32px",
            borderRadius: "50px",
            fontSize: "0.7rem",
            padding: 1.5,
          }}
          inputProps={{
            sx: {
              paddingLeft: "0.25rem !important",
              paddingRight: "1rem !important",
            },
          }}
          id="status"
          name="status"
          placeholder="Status"
          onChange={formik.handleChange}
          displayEmpty
          renderValue={(selected) => {
            if ((selected as GoalStatusType) === "") {
              return (
                <Typography variant="body2" fontSize="0.7rem">
                  Status
                </Typography>
              );
            }
            return selected;
          }}
        >
          <MenuItem disabled value="Status">
            <Typography variant="body2" fontSize="0.8rem">
              Status
            </Typography>
          </MenuItem>
          {statusOptions.map((status) => {
            if (status !== "") {
              return (
                <MenuItem key={status} value={status}>
                  <Typography variant="body2" fontSize="0.8rem">
                    {status}
                  </Typography>
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </Grid>
    </Grid>
  );
};

export default GoalCardChips;
