import { functions } from "../../config/firebase";
import { httpsCallable } from "@firebase/functions";
import LoadingSpinner from "../layout/LoadingSpinner";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { User } from "@beyondrealityapp/core/user/types";

const UserTable = () => {
  const getUsers = httpsCallable(functions, "fetchUsers") as () => Promise<{
    data: User[];
  }>;
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleVerifiedChange = (uid: string, verified: boolean) => {
    const updateUser = httpsCallable(functions, "updateUser") as (data: {
      uid: string;
      customClaims: { verified: boolean };
    }) => Promise<{ data: User }>;
    updateUser({
      uid,
      customClaims: {
        verified: !verified,
      },
    })
      .then((result) => {
        const updatedUsers = users.map((user) => {
          if (user.uid === uid) {
            return {
              ...user,
              customClaims: {
                ...user.customClaims,
                verified: !user.customClaims.verified,
              },
            };
          }
          return user;
        });
        setUsers(updatedUsers);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    getUsers()
      .then((result) => {
        setUsers(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Admin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <LoadingSpinner />
              </TableCell>
            </TableRow>
          ) : (
            users.map((user) => (
              <TableRow key={user.uid}>
                <TableCell>{user.displayName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Switch
                    checked={user.customClaims.verified}
                    color="primary"
                    onChange={() => {
                      handleVerifiedChange(
                        user.uid,
                        user.customClaims.verified
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.customClaims.admin}
                    color="primary"
                    disabled
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
