import { ErrorMessage } from "@beyondrealityapp/core/shared/constants";
import NewJournalEntryButton from "./NewJournalEntryButton";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { useNavigate } from "react-router";
import { useCreateUpdateJournalEntryMutation } from "../../../api/journal";
import { useState } from "react";

const NewReviewButtonController = () => {
  const [createUpdateJournalEntry] = useCreateUpdateJournalEntryMutation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = () => {
    setLoading(true);
    createUpdateJournalEntry(undefined)
      .then((response) => {
        if ("data" in response && response.data) {
          navigate(`/${Page.JOURNAL}/${response.data.id}`);
        } else if ("error" in response) {
          console.error(response.error);
        } else {
          console.error(ErrorMessage.UNKNOW_ERROR);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <NewJournalEntryButton
      title="New Journal Entry"
      description="Create a new journal entry"
      onClick={onClick}
      loading={loading}
    />
  );
};

export default NewReviewButtonController;
