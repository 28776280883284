import { Alert } from "@mui/material";
import { auth } from "../../config/firebase";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { signInWithEmailAndPassword } from "@firebase/auth";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

type SignInFormValues = {
  email: string;
  password: string;
};

type SignInFormErrors = {
  email?: string;
  password?: string;
};

interface FirebaseAuthError {
  code: string;
  message: string;
}

type LoginHandler = (signInFormValues: SignInFormValues) => void;

const validate = (values: SignInFormValues) => {
  const errors: SignInFormErrors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

export const SignInForm = () => {
  const [logInError, setLogInError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const loginHandler: LoginHandler = (values) => {
    setIsSubmitting(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          navigate(Page.HOME);
        }
      })
      .catch((error: FirebaseAuthError) => {
        console.log(error);
        if (error.code === "auth/user-not-found") {
          setLogInError("User not found");
          return;
        }
        if (error.code === "auth/wrong-password") {
          setLogInError("Invalid password");
          return;
        }
        if (error.code === "auth/invalid-email") {
          setLogInError("Invalid email");
          return;
        }
        if (error.code === "auth/user-disabled") {
          setLogInError("User is disabled");
          return;
        }
        if (error.code === "auth/network-request-failed") {
          setLogInError("Network request failed");
          return;
        }
        setLogInError("An error occurred");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      loginHandler(values);
      resetForm();
    },
  });
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      onChange={() => setLogInError("")}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={formik.handleChange}
        value={formik.values.email}
        helperText={formik.touched.email && formik.errors.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={formik.handleChange}
        value={formik.values.password}
        helperText={formik.touched.password && formik.errors.password}
        error={formik.touched.password && Boolean(formik.errors.password)}
      />
      <Collapse in={Boolean(logInError)}>
        <Alert severity="error">{logInError}</Alert>
      </Collapse>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        endIcon={
          isSubmitting ? <CircularProgress size={20} color="inherit" /> : null
        }
      >
        Sign In
      </Button>
    </Box>
  );
};
