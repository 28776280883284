import { auth } from "../config/firebase";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Navigate } from "react-router";
import { Page } from "@beyondrealityapp/core/shared/constants";
import PageContainer from "../components/layout/PageContainer";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { sendEmailVerification } from "firebase/auth";
import SubmitButton from "../components/layout/SubmitButton";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

const AwaitingVerification = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [confirmationEmailSent, setConfirmationEmailSent] =
    useState<boolean>(false);
  const [accountVerified, setAccountVerified] = useState<boolean>(false);
  const user = auth.currentUser;

  useEffect(() => {
    const checkAccountVerified = async () => {
      if (!user) {
        return;
      }
      const idTokenResult = await user.getIdTokenResult();
      const verified = idTokenResult?.claims.verified as boolean;
      setAccountVerified(verified);
    };
    checkAccountVerified();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate replace to={Page.SIGN_IN} />;
  }

  if (user && user.emailVerified && accountVerified) {
    return <Navigate replace to={Page.HOME} />;
  }

  const logoutHandler = async () => {
    auth
      .signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const sendEmailVerificationHandler = async () => {
    setSubmitting(true);
    if (!user) {
      setError("User not found");
      setSubmitting(false);
      return;
    }
    sendEmailVerification(user)
      .then(() => {
        setConfirmationEmailSent(true);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <PageContainer>
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/logo.svg"
          alt="Logo"
          style={{
            width: "20%",
            height: "20%",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        />
        <Typography variant="h4" color="primary.main">
          Beyond Reality
        </Typography>
        <Typography variant="subtitle2">
          Become the best version of yourself
        </Typography>
      </Box>
      <Typography variant="subtitle1" paddingBottom={2}>
        Please check your email for a confirmation link and wait for an admin to
        approve your account.
      </Typography>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 1,
          }}
        >
          {user?.emailVerified ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RadioButtonUncheckedIcon color="error" />
          )}
          <Typography sx={{ marginLeft: 2 }}>
            {user?.emailVerified ? "Email verified" : "Email not verified"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {accountVerified ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RadioButtonUncheckedIcon color="error" />
          )}
          <Typography sx={{ marginLeft: 2 }}>
            {accountVerified ? "Account verified" : "Account not verified"}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!user?.emailVerified && (
          <SubmitButton
            text="Resend e-mail"
            submittingText="Resending e-mail"
            submitting={submitting}
            onClick={sendEmailVerificationHandler}
          />
        )}
        <Button variant="contained" onClick={logoutHandler}>
          Logout
        </Button>
      </Box>
      <Box sx={{ paddingY: 2 }}>
        {error && (
          <Alert severity="error" sx={{}}>
            {error}
          </Alert>
        )}
        {confirmationEmailSent && (
          <Alert severity="success">
            Confirmation email sent. Please check your email.
          </Alert>
        )}
      </Box>
    </PageContainer>
  );
};

export default AwaitingVerification;
