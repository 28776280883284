import { array, number, object, string } from "yup";
import { Page } from "@beyondrealityapp/core/shared/constants";
import {
  ReviewFormType,
  ReviewedGoalType,
} from "@beyondrealityapp/core/review/types";
import ReviewFlowView from "./ReviewFlowView";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useEffect, useState } from "react";
import { useFetchGoalQuery } from "../../../api/goals";
import {
  useUpdateReviewMutation,
  useFetchReviewQuery,
} from "../../../api/review";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { useRef } from "react";

const ReviewFlowController = () => {
  const [updateReview] = useUpdateReviewMutation();
  const { reviewId, currentGoal } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const [currentGoalIndex, setCurrentGoalIndex] = useState<number>(0);
  const [currentGoalId, setCurrentGoalId] = useState<string>("");
  const [submitError, setSubmitError] = useState<string>("");
  const {
    data: review,
    isError: isFetchReviewError,
    error: fetchReviewError,
  } = useFetchReviewQuery(reviewId ?? "", {
    skip: !reviewId,
  });
  const {
    data: goal,
    isFetching: isFetchingGoal,
    isError: isFetchGoalError,
    error: fetchGoalError,
  } = useFetchGoalQuery(currentGoalId ?? "", {
    skip: !currentGoalId,
  });

  const ReviewSchema = object({
    id: string().required(),
    uid: string().required(),
    type: string().required(),
    reviewedGoals: array().of(
      object({
        satisfaction: number().required(),
        motivation: string(),
        newCurrent: string(),
        oldCurrent: string(),
        kpi: string(),
      })
    ),
  });

  const submitHandler = (values: ReviewFormType) => {
    setSubmitError("");

    if (Object.keys(formik.errors).length > 0) {
      setSubmitError("Please fill out all fields");
      return;
    }

    updateReview(values)
      .then(() => {
        navigate(`/${Page.REVIEWS}`);
      })
      .catch((error) => {
        setSubmitError(error.message);
      });
  };

  const getInitialValues = () => {
    if (location.state) {
      return location.state.review;
    } else {
      return {
        id: "",
        type: "",
        reviewedGoals: [] as ReviewedGoalType[],
      };
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: ReviewSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (isFetchReviewError) {
      dispatch(
        showSnackbar({
          message: fetchReviewError as string,
          severity: Severity.ERROR,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchReviewError]);

  useEffect(() => {
    if (currentGoal) {
      setCurrentGoalIndex(parseInt(currentGoal));
    }
  }, [currentGoal]);

  useEffect(() => {
    const loadReview = async () => {
      if (location.state) {
        formik.setValues(location.state.review);
        setCurrentGoalId(
          location.state.review.reviewedGoals[currentGoalIndex].id
        );
      }
      if (!location.state && review) {
        formik.setValues(review.toForm());
        setCurrentGoalId(review.reviewedGoals[currentGoalIndex].id);
      }
    };
    loadReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId, currentGoalIndex, review]);

  // Add event listener to detect enter key press and handle next click
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        if (event.key === "Enter" && !event.shiftKey) {
          handleNextClick();
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  const handleNextClick = () => {
    const isLastGoal =
      currentGoalIndex + 1 === formik.values.reviewedGoals.length;

    if (isLastGoal) {
      formik.submitForm();
    } else {
      navigate(`/${Page.REVIEWS}/${reviewId}/${currentGoalIndex + 1}`, {
        state: { review: formik.values },
      });
    }
  };

  const handleBackClick = () => {
    if (currentGoal) {
      if (currentGoalIndex - 1 >= 0) {
        navigate(`/${Page.REVIEWS}/${reviewId}/${currentGoalIndex - 1}`, {
          state: { review: formik.values },
        });
      }
    }
  };

  return (
    <ReviewFlowView
      formik={formik}
      formRef={formRef}
      goal={goal}
      isFetchGoalError={isFetchGoalError}
      fetchGoalError={fetchGoalError as string}
      loadingGoal={isFetchingGoal}
      submitError={submitError}
      currentGoalIndex={currentGoalIndex}
      handleNextClick={handleNextClick}
      handleBackClick={handleBackClick}
    />
  );
};

export default ReviewFlowController;
