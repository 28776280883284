import { auth, db } from "../../config/firebase";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import DeleteIcon from "@mui/icons-material/Delete";
import { Empty } from "@beyondrealityapp/core/shared/constants";
import IconButton from "@mui/material/IconButton";
import { array, object, string } from "yup";
import Snackbar from "@mui/material/Snackbar";
import SubmitDeleteActions from "../layout/SubmitDeleteActions";
import Textfield from "@mui/material/TextField";
import {
  PersonalityTestTestField,
  PersonalityTestType,
  PersonalityTestForm,
} from "@beyondrealityapp/core/personality_test/types";
import { TransitionGroup } from "react-transition-group";
import Typograhpy from "@mui/material/Typography";
import { getIn, useFormik } from "formik";
import { useState, useEffect } from "react";
import { usePersonalityTests } from "../../hooks/usePersonalityTests";
import useUserRole from "../../hooks/useUserRole";
import { v4 as uuidv4 } from "uuid";

type AddPersonalityTestFormProps = {
  isAdminPanel?: boolean;
  personalityTest?: PersonalityTestType;
  handleGoalModalClose?: () => void;
};

const AddPersonalityTestForm: React.FC<AddPersonalityTestFormProps> = ({
  isAdminPanel,
  personalityTest,
  handleGoalModalClose,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [allowDelete, setAllowDelete] = useState<boolean>(false);
  const [error, setError] = useState<string>(Empty.STRING);
  const userRole = useUserRole();

  const [personalityTestCreated, setPersonalityTestCreated] =
    useState<boolean>(false);
  const { createUpdatePersonalityTest, deletePersonalityTest } =
    usePersonalityTests(db, auth);

  const PersonalityTestSchema = object({
    name: string().required("Please fill in a name for this test"),
    description: string(),
    link: string().url(),
    testFields: array()
      .of(
        object().shape({
          id: string(),
          name: string().required("Please fill in a name for this test field"),
          placeholder: string(),
          value: string(),
        })
      )
      .min(1, "Please add a minimum of one test field"),
  });

  const initialValues: PersonalityTestForm = {
    id: personalityTest?.id ? personalityTest.id : Empty.STRING,
    name: personalityTest?.name ? personalityTest.name : Empty.STRING,
    description: personalityTest?.description
      ? personalityTest.description
      : Empty.STRING,
    link: personalityTest?.link ? personalityTest.link : Empty.STRING,
    testFields: personalityTest?.testFields
      ? personalityTest.testFields
      : ([] as PersonalityTestTestField[]),
  };

  const submitHandler = () => {
    setError(Empty.STRING);
    setSubmitting(true);
    createUpdatePersonalityTest(formik.values, userRole, isAdminPanel)
      .then(() => {
        setPersonalityTestCreated(true);
        formik.resetForm();
        if (handleGoalModalClose) {
          handleGoalModalClose();
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validationSchema: PersonalityTestSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const cancelHandler = () => {
    formik.resetForm();
    if (handleGoalModalClose) {
      handleGoalModalClose();
    }
  };

  const deleteHandler = () => {
    setError(Empty.STRING);
    setDeleting(true);
    if (personalityTest) {
      deletePersonalityTest(personalityTest?.id)
        .then(() => {
          if (handleGoalModalClose) {
            handleGoalModalClose();
          }
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setDeleting(false);
        });
    }
  };

  const addFieldHandler = () => {
    formik.setFieldValue(
      "testFields",
      formik.values.testFields.concat({
        id: uuidv4(),
        name: Empty.STRING,
        placeholder: "Add test field",
        value: "",
      })
    );
  };

  const deleteFieldHandler = (id: string) => {
    formik.setFieldValue(
      "testFields",
      formik.values.testFields.filter((field) => field.id !== id)
    );
  };

  useEffect(() => {
    if (typeof formik.errors.testFields === "string") {
      setError(formik.errors.testFields);
    }
    if (personalityTest) {
      setAllowDelete(true);
    }
  }, [personalityTest, formik.errors.testFields]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          "& .MuiTextField-root": { marginTop: 1 },
        }}
      >
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isAdminPanel ? (
            <Alert severity="warning" sx={{ marginBottom: 1 }}>
              Any tests that will be added here, will be automatically added to
              all users. Be careful!
            </Alert>
          ) : null}
          {personalityTest?.id ? (
            <Alert severity="warning">
              Please note that all previous test scores will be deleted when
              changing the Personality Test.
            </Alert>
          ) : null}
          <Textfield
            key="name"
            id="name"
            name="name"
            label="Name"
            placeholder="Name of the test"
            variant="standard"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
          <Textfield
            key="description"
            id="description"
            name="description"
            label="Description"
            placeholder="Description of the test"
            variant="standard"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            fullWidth
            multiline
          />
          <Textfield
            key="link"
            id="link"
            name="link"
            label="Link"
            placeholder="Link to the test"
            variant="standard"
            value={formik.values.link}
            onChange={formik.handleChange}
            error={formik.touched.link && Boolean(formik.errors.link)}
            helperText={formik.touched.link && formik.errors.link}
            fullWidth
          />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typograhpy variant="h6">Test score fields</Typograhpy>
            <Typograhpy variant="body2">
              Add the name of the fields you want to add to the test. Example:
              Red, Blue, Yellow and Green for the Insight Discovery test
            </Typograhpy>
            <TransitionGroup>
              {formik.values.testFields.map((field, index) => (
                <Collapse key={field.id}>
                  <Textfield
                    key={field.id}
                    id={field.id}
                    name={field.id}
                    placeholder={field.placeholder}
                    variant="standard"
                    value={getIn(formik.values, `testFields[${index}].name`)}
                    onChange={(event) =>
                      formik.setFieldValue(`testFields.${index}`, {
                        id: field.id,
                        name: event?.target.value,
                        placeholder: "Add test field",
                        value: "",
                      })
                    }
                    error={
                      getIn(formik.touched, `testFields[${index}].name`) &&
                      Boolean(getIn(formik.errors, `testFields[${index}].name`))
                    }
                    helperText={
                      getIn(formik.touched, `testFields[${index}].name`) &&
                      getIn(formik.errors, `testFields[${index}].name`)
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => deleteFieldHandler(field.id)}
                        >
                          <DeleteIcon color="inherit" />
                        </IconButton>
                      ),
                    }}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
            <Button
              variant="contained"
              onClick={addFieldHandler}
              startIcon={<AddIcon />}
              sx={{ marginTop: 1 }}
            >
              Add field
            </Button>
          </Box>
          <Collapse in={error !== Empty.STRING}>
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          </Collapse>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 2,
              paddingBottom: 2,
            }}
          >
            <SubmitDeleteActions
              submitting={submitting}
              allowDelete={allowDelete}
              cancelHandler={cancelHandler}
              deleteHandler={deleteHandler}
              deleting={deleting}
            />
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={personalityTestCreated}
        autoHideDuration={6000}
        onClose={() => setPersonalityTestCreated(false)}
        message="Personality test created 🎉"
      />
    </>
  );
};

export default AddPersonalityTestForm;
