import { useEffect, useState } from "react";

export const useLoadImage = (imageSrc: string, maxHeight?: number) => {
  const placeHolderImage = "assets/placeholder-image.png";
  const [image, setImage] = useState<string>(placeHolderImage);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const loadImage = (src: string) => {
    return new Promise<string>((resolve, reject) => {
      if (!src) {
        resolve("");
      }
      const image = new Image();
      if (src.includes("unsplash") && maxHeight) {
        src += `&h=${maxHeight}`;
      }
      image.src = src;
      image.onload = () => {
        resolve(src);
      };
      image.onerror = () => {
        reject();
      };
    });
  };

  useEffect(() => {
    setLoading(true);
    setImage(placeHolderImage);
    loadImage(imageSrc)
      .then((imageContent) => {
        setImage(imageContent);
      })
      .catch(() => {
        setError("Error loading image");
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc]);

  return { image, loading, error };
};
