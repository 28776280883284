import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {
  GoalFilterType,
  GoalPeriodType,
  GoalStatusType,
} from "@beyondrealityapp/core/goal/types";
import Select from "@mui/material/Select/Select";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

type ListFilterViewProps = {
  filter: GoalFilterType;
  open: boolean;
  toggleOpen: () => void;
  handleStatusChange: (event: SelectChangeEvent<string[]>) => void;
  statusChipDeleteHandler: (value: GoalStatusType) => void;
  statusFilterOptions: GoalStatusType[];
  handlePeriodChange: (event: SelectChangeEvent<string[]>) => void;
  periodChipDeleteHandler: (value: GoalPeriodType) => void;
  periodFilterOptions: GoalPeriodType[];
  handleThemeChange: (event: SelectChangeEvent<string[]>) => void;
  themeChipDeleteHandler: (value: string) => void;
  themeFilterOptons: string[];
  handleShowChildrenChange: (showChildren: boolean) => void;
};

const ListFilterView: React.FC<ListFilterViewProps> = ({
  filter,
  open,
  toggleOpen,
  handleStatusChange,
  statusChipDeleteHandler,
  statusFilterOptions,
  handlePeriodChange,
  periodChipDeleteHandler,
  periodFilterOptions,
  handleThemeChange,
  themeChipDeleteHandler,
  themeFilterOptons,
  handleShowChildrenChange,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Button startIcon={<FilterAltIcon />} size="small" onClick={toggleOpen}>
        {open ? "Close filters" : "Open filters"}
      </Button>
      <Collapse in={open} sx={{ width: "100%," }}>
        <FormControl margin="dense" sx={{ width: "100%" }}>
          <InputLabel id="status" variant="standard">
            Status
          </InputLabel>
          <Select
            id="status"
            name="status"
            label="Status"
            variant="standard"
            placeholder="Select status"
            value={filter.status}
            onChange={handleStatusChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: GoalStatusType) => (
                  <Chip
                    key={value}
                    label={value}
                    deleteIcon={
                      <CloseIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => {
                      statusChipDeleteHandler(value);
                    }}
                    sx={{
                      backgroundColor:
                        value === "Completed"
                          ? "success.main"
                          : value === "In progress"
                          ? "info.main"
                          : value === "Not started"
                          ? "default"
                          : value === "Overdue"
                          ? "error.main"
                          : undefined,
                      color:
                        value === "Completed"
                          ? "success.contrastText"
                          : value === "In progress"
                          ? "info.contrastText"
                          : value === "Not started"
                          ? "default"
                          : value === "Overdue"
                          ? "error.contrastText"
                          : undefined,
                    }}
                    size="small"
                  />
                ))}
              </Box>
            )}
            multiple
          >
            {statusFilterOptions.map((status) => (
              <MenuItem key={status} value={status}>
                <ListItemText primary={status} />
                {filter.status.includes(status) && (
                  <CheckBoxIcon color="primary" fontSize="small" />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="dense" sx={{ width: "100%" }}>
          <InputLabel id="period" variant="standard">
            Period
          </InputLabel>
          <Select
            id="period"
            name="period"
            label="Period"
            variant="standard"
            placeholder="Select period"
            value={filter.period}
            onChange={handlePeriodChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: GoalPeriodType) => (
                  <Chip
                    key={value}
                    label={value}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => {
                      periodChipDeleteHandler(value);
                    }}
                    size="small"
                  />
                ))}
              </Box>
            )}
            multiple
          >
            {periodFilterOptions.map((period) => (
              <MenuItem key={period} value={period}>
                <ListItemText primary={period} />
                {filter.period.includes(period) && (
                  <CheckBoxIcon color="primary" fontSize="small" />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="dense" sx={{ width: "100%" }}>
          <InputLabel id="theme" variant="standard">
            Theme
          </InputLabel>
          <Select
            id="theme"
            name="theme"
            label="Theme"
            variant="standard"
            placeholder="Select theme"
            value={filter.theme}
            onChange={handleThemeChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: string) => (
                  <Chip
                    key={value}
                    label={value}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => {
                      themeChipDeleteHandler(value);
                    }}
                    size="small"
                  />
                ))}
              </Box>
            )}
            multiple
          >
            {themeFilterOptons.map((theme) => (
              <MenuItem key={theme} value={theme}>
                <ListItemText primary={theme} />
                {filter.theme.includes(theme) && (
                  <CheckBoxIcon color="primary" fontSize="small" />
                )}
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography color="text.secondary">
              Show subgoals
            </Typography>
            <Switch
              checked={filter.showChildren}
              onChange={(event) => {
                handleShowChildrenChange(event.target.checked);
              }}
              inputProps={{ "aria-label": "Show children" }}
            />
          </Box>
        </FormControl>
      </Collapse>
    </Box>
  );
};

export default ListFilterView;
