import PageContainer from "../components/layout/PageContainer";
import ReviewFlowController from "../components/reviews/review_flow/ReviewFlowController";

const ReviewDetails = () => {
  return (
    <PageContainer>
      <ReviewFlowController />
    </PageContainer>
  );
};

export default ReviewDetails;
