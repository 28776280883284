import { auth, db } from "../../config/firebase";
import AddPersonalityTest from "../personality_tests/AddPersonalityTest";
import Box from "@mui/material/Box";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { Order } from "@beyondrealityapp/core/shared/constants";
import PersonalityTest from "../personality_tests/PersonalityTest";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { useFetchPersonalityTests } from "../../hooks/usePersonalityTests";

type PersonalityTestsOverviewProps = {
  isAdminPanel?: boolean;
};

const PersonalityTestsOverview: React.FC<PersonalityTestsOverviewProps> = ({
  isAdminPanel,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { personalityTests, loading, fetchPersonalityTests } =
    useFetchPersonalityTests(db, auth);

  useEffect(() => {
    const unsubscribe = fetchPersonalityTests(
      isAdminPanel,
      "name",
      Order.ASCENDING
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      {!isAdminPanel && (
        <Box
          sx={{
            marginBottom: 2,
            marginX: 2,
          }}
        >
          <Typography variant="h6">Personality tests</Typography>
          <Typography variant="body1" gutterBottom>
            Take on of the existing personality tests or create your own.
          </Typography>
        </Box>
      )}
      {loading && <LoadingSpinner />}
      {!loading &&
        personalityTests.map((test) => (
          <PersonalityTest
            expanded={expanded}
            test={test}
            handleChange={handleChange}
            key={test.id}
          />
        ))}
      {!loading && <AddPersonalityTest isAdminPanel={isAdminPanel} />}
    </>
  );
};

export default PersonalityTestsOverview;
