import {
  ErrorMessage,
  Severity,
} from "@beyondrealityapp/core/shared/constants";
import NewReviewButton from "./NewReviewButton";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useCreateReviewMutation } from "../../../api/review";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";

type NewReviewButtonControllerProps =
  | {
      title: string;
      description: string;
      type: "periodic";
      theme?: undefined;
    }
  | {
      title: string;
      description: string;
      type: "thematic";
      theme: string;
    };

// Create a NewReviewButtonControllerProps type where it has the following properties:
// - title: string
// - description: string
// - type: "periodic" | "thematic"
// - theme?: string
// Theme should be mandatory, when the type is "thematic" and optional when the type is "periodic".

const NewReviewButtonController: React.FC<NewReviewButtonControllerProps> = ({
  title,
  description,
  type,
  theme,
}) => {
  const [createReview] = useCreateReviewMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = () => {
    setLoading(true);
    createReview(undefined)
      .then((response) => {
        if ("data" in response && response.data) {
          navigate(`/${Page.REVIEWS}/${response.data.id}/0`);
        } else if ("error" in response) {
          dispatch(
            showSnackbar({ message: response.error, severity: Severity.ERROR })
          );
        } else {
          dispatch(
            showSnackbar({
              message: ErrorMessage.UNKNOW_ERROR,
              severity: Severity.ERROR,
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <NewReviewButton
      title={title}
      description={description}
      onClick={onClick}
      loading={loading}
    />
  );
};

export default NewReviewButtonController;
