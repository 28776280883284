import NewReviewButtonController from "../new_review_button/NewReviewButtonControler";

const PeriodicReviewButton = () => {
  return (
    <NewReviewButtonController
      title="Periodic Review"
      description="Start a review covering all active goals"
      type="periodic"
    />
  );
};

export default PeriodicReviewButton;
