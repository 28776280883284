import { GoalFilterType } from "@beyondrealityapp/core/goal/types";
import GoalsListView from "./GoalsListView";
import { useEffect } from "react";
import { useGoalsFilter } from "../../../hooks/useGoalsFilter";
import { useState } from "react";
import { useFetchGoalsQuery } from "../../../api/goals";

type GoalsListControllerProps = {
  listId: string;
  defaultFilter?: GoalFilterType;
  hideFilter?: boolean;
  compact?: boolean;
  noResultsMessage?: string;
};

const GoalsListController: React.FC<GoalsListControllerProps> = ({
  listId,
  defaultFilter,
  hideFilter,
  compact,
  noResultsMessage,
}) => {
  const { filter, setGoalsFilter } = useGoalsFilter(
    defaultFilter ?? {
      status: [],
      period: [],
      theme: [],
      showChildren: true,
    },
    listId
  );
  const [page, setPage] = useState<number>(1);
  const {
    data: goals,
    isFetching,
    isLoading,
    isError,
    error,
  } = useFetchGoalsQuery({
    statusFilter: filter.status,
    periodFilter: filter.period,
    themeFilter: filter.theme,
    shouldShowChildren: filter.showChildren,
    page,
  });

  const handleFilterChange = (newFilter: GoalFilterType) => {
    setGoalsFilter(newFilter);
    setPage(1);
  };

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      const hasMore = goals?.length === 10 * page;
      if (scrolledToBottom && !isFetching && hasMore) {
        setPage(page + 1);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching, goals]);

  return (
    <>
      <GoalsListView
        goals={goals}
        loading={isLoading}
        loadingMore={isFetching}
        isError={isError}
        error={error}
        hideFilter={hideFilter}
        filter={filter}
        setFilter={handleFilterChange}
        compact={compact}
        noResultsMessage={noResultsMessage}
      />
    </>
  );
};

export default GoalsListController;
