import Alert from "@mui/material/Alert";
import { auth, db } from "../../config/firebase";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Empty } from "@beyondrealityapp/core/shared/constants";
import Snackbar from "@mui/material/Snackbar";
import SubmitButton from "../layout/SubmitButton";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { usePersonalityTests } from "../../hooks/usePersonalityTests";

type PersonalityTestFormField = {
  id: string;
  name: string;
  placeholder: string;
  value: string;
};

type PersonalityTestFormProps = {
  testId: string;
  testName: string;
  fields: PersonalityTestFormField[];
  uid: string;
};

const PersonalityTestForm: React.FC<PersonalityTestFormProps> = ({
  testId,
  testName,
  fields,
  uid,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>(Empty.STRING);
  const [personalityTestUpdated, setPersonalityTestUpdated] =
    useState<boolean>(false);
  const { updatePersonalityTestScore } = usePersonalityTests(db, auth);
  const initialValues: PersonalityTestFormField[] = [];

  useEffect(() => {
    fields.forEach((field, index) => {
      formik.setFieldValue(`[${index}]`, {
        id: field.id,
        name: field.name,
        placeholder: field.placeholder,
        value: field.value,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const submitHandler = async () => {
    setSubmitting(true);
    setError(Empty.STRING);
    updatePersonalityTestScore(testId, formik.values)
      .catch((error) => {
        setError(error.message);
      })
      .then(() => {
        setPersonalityTestUpdated(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            width: "100%",
            marginX: 2,
          }}
        >
          {fields.map((field, index) => (
            <TextField
              key={field.id}
              id={field.id}
              name={field.id}
              label={field.name}
              placeholder={field.placeholder}
              variant="standard"
              value={formik.values[index]?.value || ""}
              onChange={(event) =>
                formik.setFieldValue(`[${index}]`, {
                  id: field.id,
                  name: field.name,
                  placeholder: field.placeholder,
                  value: event?.target.value,
                })
              }
              error={formik.touched[index] && Boolean(formik.errors[index])}
              sx={{ marginTop: 1 }}
              fullWidth
            />
          ))}
          <Box
            sx={{
              width: "100%",
              marginTop: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {uid !== "system" ? <SubmitButton submitting={submitting} /> : null}
          </Box>
          <Collapse in={error !== Empty.STRING}>
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          </Collapse>
        </Box>
      </Box>
      <Snackbar
        open={personalityTestUpdated}
        autoHideDuration={6000}
        onClose={() => setPersonalityTestUpdated(false)}
        message={`Updated score for ${testName} 🥳`}
      />
    </>
  );
};

export default PersonalityTestForm;
