import { CoverImage } from "@beyondrealityapp/core/shared/types";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Launch from "@mui/icons-material/Launch";

type ImageGridViewProps = {
  images: CoverImage[];
  onImageClickHandler: (image: CoverImage) => void;
  columns: number;
  gap: number;
};

const ImageGridView: React.FC<ImageGridViewProps> = ({
  images,
  onImageClickHandler,
  columns,
  gap,
}) => {
  return (
    <ImageList
      sx={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
      }}
      cols={columns}
      gap={gap}
      rowHeight={200}
    >
      {images.map((image) => (
        <ImageListItem
          key={image.id}
          onClick={() => onImageClickHandler(image)}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
            overflow: "hidden",
          }}
        >
          <img
            src={`${image.image_url}`}
            alt={`@${image.username}`}
            loading="lazy"
            style={{ maxHeight: "200px" }}
          />
          <ImageListItemBar
            title={`@${image.username}`}
            actionIcon={
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${image.alt_description}`}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(image.user_url, "_blank");
                }}
              >
                <Launch fontSize="small" />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageGridView;
