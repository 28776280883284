import { auth } from "../../config/firebase";
import LoadingSpinner from "../layout/LoadingSpinner";
import { Navigate, Outlet } from "react-router-dom";
import { Page, Role } from "@beyondrealityapp/core/shared/constants";
import { useEffect, useState } from "react";

type ProtectedRouteProps = {
  allowedRoles: string[];
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles }) => {
  const [authResult, setAuthResult] = useState<
    | Page.SIGN_IN
    | Page.HOME
    | Page.AWAITING_VERIFICATION
    | Page.OUTLET
    | null
  >(null);

  useEffect(() => {
    const checkAuth = async () => {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();

      // Check if roles defined, otherwise always redirect to loing
      if (allowedRoles.length === 0) {
        setAuthResult(Page.SIGN_IN);
        return;
      }

      // Check if user is logged in, otherwise redirect to login
      const user = auth.currentUser;
      if (!user) {
        setAuthResult(Page.SIGN_IN);
        return;
      }

      if (!user.emailVerified || !idTokenResult?.claims.verified) {
        setAuthResult(Page.AWAITING_VERIFICATION);
        return;
      }

      // Check if user has admin role, otherwise redirect to home
      if (
        allowedRoles.indexOf(Role.USER) === -1 &&
        allowedRoles.indexOf(Role.ADMIN) >= 0 &&
        !idTokenResult?.claims.admin
      ) {
        setAuthResult(Page.HOME);
      } else {
        setAuthResult(Page.OUTLET);
        return;
      }
    };

    checkAuth();
  }, [allowedRoles]);

  if (authResult === null) {
    return <LoadingSpinner />;
  }

  if (authResult === Page.SIGN_IN) {
    return <Navigate to={Page.SIGN_IN} />;
  }

  if (authResult === Page.AWAITING_VERIFICATION) {
    return <Navigate to={Page.AWAITING_VERIFICATION} />;
  }

  if (authResult === Page.HOME) {
    return <Navigate to={Page.HOME} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
