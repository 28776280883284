import { hideSnackbar } from "../../../features/snackbar";
import GenericSnackBarView from "./GenericSnackbarView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../config/store";

const GenericSnackbarController = () => {
  const dispatch = useDispatch();
  const { open, message, severity, action } = useSelector(
    (state: RootState) => state.snackbar
  );
  const onClose = () => {
    dispatch(hideSnackbar());
  };
  return (
    <GenericSnackBarView
      open={open}
      message={message}
      severity={severity}
      action={action}
      onClose={onClose}
    />
  );
};

export default GenericSnackbarController;
