import { Auth } from "@firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Firestore } from "firebase/firestore";
import { Operator, Model } from "@beyondrealityapp/core/shared/constants";
import { UserField } from "@beyondrealityapp/core/user/constants";
import { UserDocument } from "@beyondrealityapp/core/user/types";

export const useFetchUsers = (db: Firestore, auth: Auth) => {
  const fetchUser = async (
    uid: string | undefined
  ): Promise<UserDocument | null> => {
    let user = null;
    if (!uid) return user;

    const q = query(
      collection(db, Model.USERS),
      where(UserField.UID, Operator.EQUAL_TO, uid)
    );

    const documents = await getDocs(q);

    if (documents.empty) return null;
    if (documents.size > 1) {
      throw new Error(
        `More than one user found for uid ${uid}. This should never happen.`
      );
    }
    documents.forEach((doc) => {
      user = doc.data() as UserDocument;
    });

    return user;
  };

  return {
    fetchUser,
  };
};
