import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPersonalityTestForm from "./AddPersonalityTestForm";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

type AddPersonalityTestProps = {
  isAdminPanel?: boolean;
};

const AddPersonalityTest: React.FC<AddPersonalityTestProps> = ({
  isAdminPanel,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleGoalModalClose = () => {
    setExpanded(false);
  };
  const handleChange = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Accordion
      sx={{ width: "100%" }}
      expanded={expanded}
      onChange={handleChange}
      id="add-personality-test"
    >
      <AccordionSummary
        aria-controls="add-personality-test"
        id="add-personality-test}-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AddCircleOutlineIcon
            color="action"
            sx={{
              marginRight: 1,
              marginY: 1,
            }}
          />
          {isAdminPanel ? (
            <Typography>Add personality test to all users</Typography>
          ) : (
            <Typography>Add personality test to your profile</Typography>
          )}
        </Box>
      </AccordionSummary>
      <AddPersonalityTestForm
        isAdminPanel={isAdminPanel}
        handleGoalModalClose={handleGoalModalClose}
      />
    </Accordion>
  );
};

export default AddPersonalityTest;
