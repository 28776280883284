import AddGoalCard from "../components/goals/add_goal_card/AddGoalCard";
import GoalsListController from "../components/goals/goals_list/GoalsListController";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PageContainer from "../components/layout/PageContainer";

const Goals = () => {
  return (
    <PageContainer>
      <List disablePadding dense>
        <ListItem
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          disableGutters
          disablePadding
        >
          <AddGoalCard />
        </ListItem>
      </List>
      <GoalsListController listId="goalsPage" />
    </PageContainer>
  );
};

export default Goals;
