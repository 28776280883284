import { auth, db } from "../../config/firebase";
import AddPersonalityTestForm from "./AddPersonalityTestForm";
import Box from "@mui/material/Box";
import DefaultModal from "../layout/DefaultModal";
import { PersonalityTestType } from "@beyondrealityapp/core/personality_test/types";
import { useNavigate } from "react-router";
import { useFetchPersonalityTests } from "../../hooks/usePersonalityTests";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const AddPersonalityTestModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [personalityTest, setPersonalityTest] = useState<PersonalityTestType>();
  const { personalityTestId } = useParams();
  const navigate = useNavigate();
  const { fetchPersonalityTest } = useFetchPersonalityTests(db, auth);

  const handleGoalModalClose = () => {
    setIsOpen(false);
    navigate(-1);
  };

  useEffect(() => {
    if (personalityTestId) {
      fetchPersonalityTest(personalityTestId).then((personalityTestDoc) => {
        setIsOpen(true);
        setPersonalityTest(personalityTestDoc);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalityTestId]);

  return (
    <DefaultModal isOpen={isOpen} onClose={handleGoalModalClose}>
      <Box sx={{ marginTop: 2, marginBottom: 1 }}>
        <AddPersonalityTestForm
          personalityTest={personalityTest}
          handleGoalModalClose={handleGoalModalClose}
          isAdminPanel={personalityTest?.uid === "system"}
        />
      </Box>
    </DefaultModal>
  );
};

export default AddPersonalityTestModal;
