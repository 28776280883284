import { auth } from "../../../config/firebase";
import { MenuItemType } from "@beyondrealityapp/core/shared/types";
import MenuView from "./MenuView";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { signOut } from "firebase/auth";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

const knowYourselfMenuItems: MenuItemType[] = [
  {
    text: "Core Values",
    icon: "Favorite",
    path: Page.CORE_VALUES,
    active: false,
  },
  {
    text: "Personality Tests",
    icon: "Quiz",
    path: Page.PERSONALITY_TEST,
    active: true,
  },
  {
    text: "Journal",
    icon: "TipsAndUpdates",
    path: Page.JOURNAL,
    active: true,
  },
];

const defineYourselfMenuItems: MenuItemType[] = [
  {
    text: "Goal Setter",
    icon: "Hiking",
    path: Page.GOAL_SETTER,
    active: false,
  },
];

const becomeYourselfMenuItems: MenuItemType[] = [
  {
    text: "Themes",
    icon: "Public",
    path: Page.THEMES,
    active: true,
  },
  {
    text: "Goals",
    icon: "CrisisAlert",
    path: Page.GOALS,
    active: true,
  },
  {
    text: "Reviews",
    icon: "EditNote",
    path: Page.REVIEWS,
    active: true,
  },
];

const settingsMenuItems: MenuItemType[] = [
  {
    text: "Settings",
    icon: "Settings",
    path: Page.SETTINGS,
    active: false,
  },
];

const adminPanelMenuItems: MenuItemType[] = [
  {
    text: "Admin Panel",
    icon: "AdminPanelSettingsIcon",
    path: Page.ADMIN_PANEL,
    active: true,
  },
];

const checkIfActiveMenuItems = (menuItems: MenuItemType[]) => {
  return menuItems.some((menuItem) => menuItem.active);
};

type MenuControllerProps = {
  open: boolean;
  toggleDrawer: () => void;
};

const MenuController: React.FC<MenuControllerProps> = ({
  open,
  toggleDrawer,
}) => {
  const [showAdminPanel, setShowAdminPanel] = useState<boolean>(false);
  const { isXs } = useBreakpoints();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    signOut(auth)
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Successfully logged out",
            severity: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error.message,
            severity: "error",
          })
        );
      });
  };

  useEffect(() => {
    const checkAuth = async () => {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      if (idTokenResult?.claims.admin) {
        setShowAdminPanel(true);
      }
    };
    checkAuth();
  }, []);
  return (
    <MenuView
      open={open}
      toggleDrawer={toggleDrawer}
      checkIfActiveMenuItems={checkIfActiveMenuItems}
      knowYourselfMenuItems={knowYourselfMenuItems}
      defineYourselfMenuItems={defineYourselfMenuItems}
      becomeYourselfMenuItems={becomeYourselfMenuItems}
      settingsMenuItems={settingsMenuItems}
      showAdminPanel={showAdminPanel}
      adminPanelMenuItems={adminPanelMenuItems}
      logoutHandler={logoutHandler}
      isXs={isXs}
    />
  );
};

export default MenuController;
