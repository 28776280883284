import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { Empty } from "@beyondrealityapp/core/shared/constants";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import { FormikState, FormikHelpers } from "formik";
import { ImprovedGoal } from "@beyondrealityapp/core/goal/types";

type ImprovedGoalAlertProps = {
  formik: FormikState<Partial<GoalFormType>> & FormikHelpers<Partial<GoalFormType>>;
  improvedGoal: ImprovedGoal;
  setImprovedGoal: (improvedGoal: ImprovedGoal) => void;
};

const ImprovedGoalAlert: React.FC<ImprovedGoalAlertProps> = ({
  formik,
  improvedGoal,
  setImprovedGoal,
}) => {
  const onSetGoalHandler = () => {
    formik.setFieldValue("content", improvedGoal.name);
    setImprovedGoal({
      name: Empty.STRING,
      reason: Empty.STRING,
    });
  };
  return (
    <Collapse in={improvedGoal.name !== ""}>
      <Alert
        severity="info"
        sx={{ marginTop: 2 }}
        icon={false}
        action={
          <Button color="inherit" onClick={onSetGoalHandler}>
            SET AS GOAL
          </Button>
        }
      >
        <AlertTitle>
          <strong>{improvedGoal.name}</strong>
        </AlertTitle>
        {improvedGoal.reason}
      </Alert>
    </Collapse>
  );
};

export default ImprovedGoalAlert;
