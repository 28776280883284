import { auth, db } from "../config/firebase";
import { Auth } from "firebase/auth";
import {
  Empty,
  Operator,
  Order,
  Model,
  Resource,
} from "@beyondrealityapp/core/shared/constants";
import {
  and,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  Firestore,
  QueryFieldFilterConstraint,
  limit,
  orderBy,
  runTransaction,
  query,
  where,
} from "firebase/firestore";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";
import { GoalField } from "@beyondrealityapp/core/goal/constants";
import {
  GoalFormType,
  GoalFirestoreType,
  GoalStatusType,
  GoalPeriodType,
} from "@beyondrealityapp/core/goal/types";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

interface FetchGoalsArgs {
  statusFilter: GoalStatusType[];
  periodFilter: GoalPeriodType[];
  themeFilter: string[];
  shouldShowChildren: boolean;
  page: number;
}

interface deleteGoalArgs {
  goalId: string;
  parentGoalId: string;
}

export const goalsApi = createApi({
  reducerPath: "goalsApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Goal"],
  endpoints: (builder) => ({
    fetchGoals: builder.query({
      queryFn: async (filter: FetchGoalsArgs) => {
        try {
          const goals = await _retrieveGoals(
            db,
            auth,
            filter.statusFilter,
            filter.periodFilter,
            filter.themeFilter,
            filter.shouldShowChildren,
            filter.page
          );
          return { data: goals };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: "Goal" as const, id })), "Goal"]
          : ["Goal"],
    }),
    fetchGoal: builder.query({
      queryFn: async (id?: string) => {
        if (!id) {
          return { error: new Error("No goal id provided") };
        }
        try {
          const goal = await _fetchGoal(id);
          return { data: goal };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: (result, error, arg) => [{ type: "Goal", id: result?.id }],
    }),
    createUpdateGoal: builder.mutation({
      queryFn: async (goalForm: Partial<GoalFormType>) => {
        try {
          const goal = await _persistGoal(goalForm);
          return { data: goal };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: (result, error, arg) => [
        arg.children || arg.parent ? "Goal" : { type: "Goal", id: arg?.id },
      ],
    }),
    deleteGoal: builder.mutation({
      queryFn: async ({ goalId, parentGoalId }: deleteGoalArgs) => {
        try {
          await _removeGoal(goalId, parentGoalId);
          return { data: goalId };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: (result, error, arg) => [
        arg.parentGoalId ? "Goal" : { type: "Goal", id: arg?.goalId },
      ],
    }),
  }),
});

export const {
  useFetchGoalsQuery,
  useFetchGoalQuery,
  useCreateUpdateGoalMutation,
  useDeleteGoalMutation,
} = goalsApi;

// TODO - Remove parentGoalId from the deleteGoalArgs
const _removeGoal = async (goalId: string, parentGoalId: string) => {
  const parentQuerySnapshot = await _queryParentGoal(db, auth, parentGoalId);
  return new Promise<void>((resolve, reject) => {
    runTransaction(db, async (transaction) => {
      if (parentQuerySnapshot && !parentQuerySnapshot.empty) {
        parentQuerySnapshot.forEach((parentDoc) => {
          transaction.update(parentDoc.ref, {
            [`children.${goalId}`]: deleteField(),
          });
        });
      }
      // TODO - Remove children goals if any
      transaction.delete(doc(db, Model.GOALS, goalId));
    })
      .then(() => {
        resolve();
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const _persistGoal = async (goalForm: Partial<GoalFormType>) => {
  const goal = GoalClass.fromForm(goalForm);

  goal.init(auth);

  const parentQuerySnapshot = await _queryParentGoal(db, auth, goal.parent);
  const goalRef = doc(db, Model.GOALS, goal.id);

  return new Promise<GoalClass>((resolve, reject) => {
    runTransaction(db, async (transaction) => {
      if (parentQuerySnapshot && !parentQuerySnapshot.empty) {
        parentQuerySnapshot.forEach((parentDoc) => {
          transaction.set(
            parentDoc.ref,
            {
              children: {
                [goal.id]: {
                  name: goal.content,
                  status: goal.status,
                  unit: goal.kpi,
                  target: goal.target,
                  current: goal.current,
                },
              },
            },
            { merge: true }
          );
        });
      }
      transaction.set(goalRef, goal.toFirestore());
    })
      .then(() => {
        resolve(goal);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _queryParentGoal = async (
  db: Firestore,
  auth: Auth,
  parentGoalId: string
) => {
  if (parentGoalId === "") {
    return undefined;
  }
  const goalsRef = collection(db, Model.GOALS);
  const q = query(
    goalsRef,
    and(
      where(GoalField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
      where(Resource.ID, Operator.EQUAL_TO, parentGoalId)
    )
  );
  return await getDocs(q);
};

const _retrieveGoals = async (
  db: Firestore,
  auth: Auth,
  statusFilter: GoalStatusType[],
  periodFilter: GoalPeriodType[],
  themeFilter: string[],
  shouldShowChildren: boolean,
  page: number
) => {
  let uid = "";
  if (auth.currentUser) {
    uid = auth.currentUser?.uid;
  }

  let statusFirestoreFilters: QueryFieldFilterConstraint[] = [];
  if (statusFilter.length !== 0) {
    const statusFilterConstraint = where(
      GoalField.STATUS,
      Operator.IN,
      statusFilter
    );
    statusFirestoreFilters = [statusFilterConstraint];
  }

  let periodFirestoreFilters: QueryFieldFilterConstraint[] = [];
  if (periodFilter.length !== 0) {
    const filterOnYear = periodFilter.includes("This year");
    const filterOnQuarter = periodFilter.includes("This quarter");
    const filterOnMonth = periodFilter.includes("This month");
    const filterOnweek = periodFilter.includes("This week");

    if (filterOnYear) {
      const inYearFilter = where(
        GoalField.IS_IN_YEAR,
        Operator.EQUAL_TO,
        filterOnYear
      );
      periodFirestoreFilters = [inYearFilter];
    } else if (filterOnQuarter) {
      const inQuarterFilter = where(
        GoalField.IS_IN_QUARTER,
        Operator.EQUAL_TO,
        filterOnQuarter
      );
      periodFirestoreFilters = [inQuarterFilter];
    } else if (filterOnMonth) {
      const inMonthFilter = where(
        GoalField.IS_IN_MONTH,
        Operator.EQUAL_TO,
        filterOnMonth
      );
      periodFirestoreFilters = [inMonthFilter];
    } else if (filterOnweek) {
      const inWeekFilter = where(
        GoalField.IS_IN_WEEK,
        Operator.EQUAL_TO,
        filterOnweek
      );
      periodFirestoreFilters = [inWeekFilter];
    }
  }

  let themeFirestoreFilters: QueryFieldFilterConstraint[] = [];
  if (themeFilter.length !== 0) {
    const themeFilterConstraint = where(
      GoalField.THEMES,
      Operator.ARRAY_CONTAINS_ANY,
      themeFilter
    );
    themeFirestoreFilters = [themeFilterConstraint];
  }

  let childrenFirestoreFilters: QueryFieldFilterConstraint[] = [];
  const hideChildren = !shouldShowChildren;
  if (hideChildren) {
    const childrenFilterConstraint = where(
      GoalField.PARENT,
      Operator.EQUAL_TO,
      Empty.STRING
    );
    childrenFirestoreFilters = [childrenFilterConstraint];
  }

  const q = query(
    collection(db, Model.GOALS),
    and(
      where(GoalField.UID, Operator.EQUAL_TO, uid),
      ...statusFirestoreFilters,
      ...periodFirestoreFilters,
      ...themeFirestoreFilters,
      ...childrenFirestoreFilters
    ),
    orderBy(GoalField.UPDATED_AT, Order.DESCENDING),
    limit(page * 10)
  );

  const goalDocs = await getDocs(q);
  const tempGoals: GoalClass[] = [];
  goalDocs.forEach((doc) => {
    tempGoals.push(
      GoalClass.fromFirestore({
        ...doc.data(),
        id: doc.id,
      } as GoalFirestoreType)
    );
  });

  return tempGoals;
};

const _fetchGoal = async (id: string) => {
  return new Promise<GoalClass>((resolve, reject) => {
    let goal = {} as GoalClass;
    const goalRef = doc(db, Model.GOALS, id);
    getDoc(goalRef)
      .then((doc) => {
        if (doc.exists()) {
          goal = GoalClass.fromFirestore({
            ...doc.data(),
            id: doc.id,
          } as GoalFirestoreType);
        }
        resolve(goal);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
