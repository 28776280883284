import { auth, storage } from "../../../config/firebase";
import { ErrorMessage, Empty } from "@beyondrealityapp/core/shared/constants";
import { object, string } from "yup";
import { OnlineContext } from "../../../providers/OnlineProvider";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";
import { ThemeFormType } from "@beyondrealityapp/core/theme/types";
import ThemeModalView from "./ThemeModalView";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useImageUpload } from "../../../hooks/useImageUpload";
import {
  useCreateUpdateThemeMutation,
  useDeleteThemeMutation,
} from "../../../api/theme";

type ThemeModalControllerProps = {
  theme?: ThemeClass;
  isOpen: boolean;
  onClose: () => void;
};

const ThemeModalController: React.FC<ThemeModalControllerProps> = ({
  theme,
  isOpen,
  onClose,
}) => {
  const [createUpdateTheme] = useCreateUpdateThemeMutation();
  const [deleteTheme] = useDeleteThemeMutation();
  const { deleteImage } = useImageUpload(storage, auth);

  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [allowDelete, setAllowDelete] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [initialValues, setInitialValues] = useState<ThemeFormType>({
    id: "",
    uid: "",
    name: "",
    description: "",
    image: "",
    imageVerticalOffset: 50,
    goals: [],
    history: [],
    metaData: {
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  });
  const isOnline = useContext(OnlineContext);

  const setImage = (image: string) => {
    formik.setFieldValue("image", image);
  };
  const imageDeleteHandler = () => {
    const uid = auth.currentUser?.uid ?? "";
    if (uid && formik.values.image.includes(uid)) {
      deleteImage(formik.values.image)
        .then(() => {
          formik.setFieldValue("image", "");
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      formik.setFieldValue("image", "");
    }
  };
  const cancelHandler = () => {
    formik.resetForm();
    onClose();
  };

  const submitHandler = () => {
    if (!isOnline) {
      setError(ErrorMessage.OFFLINE);
      return;
    }
    setError(Empty.STRING);
    setSubmitting(true);
    createUpdateTheme(formik.values)
      .then(() => {
        setSubmitting(false);
        formik.resetForm();
        onClose();
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
      });
  };

  const deleteHandler = () => {
    if (!isOnline) {
      setError(ErrorMessage.OFFLINE);
      return;
    }
    setError(Empty.STRING);
    setDeleting(true);
    deleteTheme({
      themeId: formik.values.id,
      themeName: formik.values.name,
    })
      .then(() => {
        formik.resetForm();
        onClose();
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const offSetHandler = (offset: number) => {
    formik.setFieldValue("imageVerticalOffset", offset);
  };

  const ThemeSchema = object({
    name: string().required("Please make sure to add a name"),
    description: string(),
    image: string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ThemeSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (theme) {
      setAllowDelete(true);
      setInitialValues(theme.toForm() as ThemeFormType);
    }
  }, [theme]);

  return (
    <ThemeModalView
      isOpen={isOpen}
      onClose={onClose}
      formik={formik}
      setImageModalOpen={setImageModalOpen}
      imageDeleteHandler={imageDeleteHandler}
      error={error}
      allowDelete={allowDelete}
      cancelHandler={cancelHandler}
      deleteHandler={deleteHandler}
      submitting={submitting}
      deleting={deleting}
      imageModalOpen={imageModalOpen}
      setImage={setImage}
      offSetHandler={offSetHandler}
    />
  );
};

export default ThemeModalController;
