import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type FileUploadButtonProps = {
  label: string;
  onFileChange: (file: File) => void;
};

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  label,
  onFileChange,
}) => {
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      onChange={(e) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
          onFileChange(file);
        }
      }}
    >
      {label}
      <VisuallyHiddenInput type="file" />
    </Button>
  );
};

export default FileUploadButton;
