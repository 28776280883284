import { createSlice } from "@reduxjs/toolkit";

type snackbarState = {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  action?: {
    label: string;
    onClick: () => void;
  };
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    message: "",
    severity: "success",
  } as snackbarState,
  reducers: {
    showSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    hideSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
