import { auth } from "../config/firebase";
import CryptoJS, { AES } from "crypto-js";

const useEncryptedStorage = () => {
  const getEncryptionKeyFromFirebaseAuth = async () => {
    const user = auth.currentUser;
    if (!user) {
      return null;
    }
    const idTokenResult = await user.getIdTokenResult();
    if (idTokenResult?.claims.encryptionKey) {
      return idTokenResult.claims.encryptionKey as string;
    }
  };

  const setToEncryptedStorage = async (key: string, value: string) => {
    const encryptionKey = await getEncryptionKeyFromFirebaseAuth();
    return new Promise((resolve, reject) => {
      if (!key) {
        reject("No key found");
      }
      if (!encryptionKey) {
        reject("No encryption key found");
      } else {
        const encryptedValue = AES.encrypt(value, encryptionKey).toString();
        localStorage.setItem(key, encryptedValue);
        resolve(encryptedValue);
      }
    });
  };
  const getFromEncryptedStorage = async (key: string) => {
    const encryptionKey = await getEncryptionKeyFromFirebaseAuth();
    return new Promise((resolve, reject) => {
      if (!key) {
        reject("No key found");
      }
      const encryptedValue = localStorage.getItem(key);
      if (!encryptedValue) {
        resolve(null);
        return;
      }
      if (!encryptionKey) {
        reject("No encryption key found");
        return;
      }
      const decryptedValue = AES.decrypt(
        encryptedValue,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8);
      resolve(decryptedValue);
    });
  };
  const removeFromEncryptedStorage = (key: string) => {
    localStorage.removeItem(key);
  };

  return {
    setToEncryptedStorage,
    getFromEncryptedStorage,
    removeFromEncryptedStorage,
  };
};

export default useEncryptedStorage;
