import {
  and,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QuerySnapshot,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import { Auth } from "firebase/auth";
import { auth, db } from "../config/firebase";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { ErrorMessage } from "@beyondrealityapp/core/shared/constants";
import { GoalField, GoalStatus } from "@beyondrealityapp/core/goal/constants";
import { GoalType } from "@beyondrealityapp/core/goal/types";
import {
  Model,
  Operator,
  Order,
} from "@beyondrealityapp/core/shared/constants";
import { ReviewClass } from "@beyondrealityapp/core/review/classes";
import { ReviewField } from "@beyondrealityapp/core/review/constants";
import {
  ReviewFirestoreType,
  ReviewFormType,
  ReviewListFilterType
} from "@beyondrealityapp/core/review/types";
import { ReviewVariant } from "@beyondrealityapp/core/review/constants";

export const reviewApi = createApi({
  reducerPath: "reviewApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Review"],
  endpoints: (builder) => ({
    fetchReviews: builder.query({
      queryFn: async (filter: ReviewListFilterType) => {
        try {
          const reviews = await _fetchReviews(filter);
          return { data: reviews };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["Review"],
    }),
    fetchReview: builder.query({
      queryFn: async (reviewId: string) => {
        try {
          const review = await _fetchReview(reviewId);
          return { data: review };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["Review"],
    }),
    createReview: builder.mutation({
      queryFn: async () => {
        try {
          const review = await _createReview();
          return { data: review };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Review"],
    }),
    updateReview: builder.mutation({
      queryFn: async (reviewForm: Partial<ReviewFormType>) => {
        try {
          const review = await _updateReview(reviewForm);
          return { data: review };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Review"],
    }),
    deleteReview: builder.mutation({
      queryFn: async (reviewId: string) => {
        try {
          await _deleteReview(reviewId);
          return { data: reviewId };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Review"],
    }),
  }),
});

export const {
  useFetchReviewsQuery,
  useFetchReviewQuery,
  useCreateReviewMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
} = reviewApi;

const _fetchReviews = (filter: ReviewListFilterType) => {
  const q = query(
    collection(db, Model.REVIEWS),
    where(ReviewField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
    orderBy(ReviewField.CREATED_AT, Order.DESCENDING),
    limit(filter.page * 10)
  );
  return new Promise<ReviewClass[]>((resolve, reject) => {
    getDocs(q)
      .then((querySnapshot) => {
        const reviews: ReviewClass[] = [];
        querySnapshot.forEach((doc) => {
          const review = ReviewClass.fromFirestore(
            doc.data() as ReviewFirestoreType
          );
          reviews.push(review);
        });
        resolve(reviews);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchReview = (id: string) => {
  return new Promise<ReviewClass>((resolve, reject) => {
    getDoc(doc(db, Model.REVIEWS, id)).then((doc) => {
      if (doc.exists()) {
        const review = ReviewClass.fromFirestore(
          doc.data() as ReviewFirestoreType
        );
        resolve(review);
      } else {
        reject(ErrorMessage.NOT_FOUND);
      }
    });
  });
};

const _createReview = async () => {
  const goalsQuerySnapshot = await _queryGoals(db, auth);

  return new Promise<ReviewClass>((resolve, reject) => {
    _generateReview(db, goalsQuerySnapshot)
      .then((review) => {
        resolve(review);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _generateReview = async (
  db: Firestore,
  goalsQuerySnapshot: QuerySnapshot
) => {
  return new Promise<ReviewClass>((resolve, reject) => {
    if (goalsQuerySnapshot.empty) {
      reject(ErrorMessage.NOT_FOUND);
    }
    const review = new ReviewClass({
      type: ReviewVariant.PERIODIC,
      reviewedGoals: [],
    });
    review.init(auth);

    goalsQuerySnapshot.forEach((doc) => {
      review.appendReviewedGoal(doc.id, doc.data() as GoalType);
    });

    setDoc(doc(db, Model.REVIEWS, review.id), review.toFirestore()).then(() => {
      resolve(review);
    });
  });
};

const _queryGoals = async (db: Firestore, auth: Auth) => {
  const goalsRef = collection(db, Model.GOALS);
  const q = query(
    goalsRef,
    and(
      where(GoalField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
      or(
        where(GoalField.STATUS, Operator.EQUAL_TO, GoalStatus.IN_PROGRESS),
        where(GoalField.STATUS, Operator.EQUAL_TO, GoalStatus.OVERDUE)
      )
    )
  );
  return await getDocs(q);
};

const _updateReview = async (review: Partial<ReviewFormType>) => {
  return new Promise<void>((resolve, reject) => {
    const updatedReview = ReviewClass.fromForm(review);

    updatedReview.setUpdatedTimestamp();

    runTransaction(db, async (transaction) => {
      updatedReview.reviewedGoals.forEach((reviewedGoal) => {
        transaction.update(doc(db, Model.GOALS, reviewedGoal.id), {
          current: reviewedGoal.newCurrent,
        });
      });
      transaction.update(doc(db, Model.REVIEWS, updatedReview.id), {
        reviewedGoals: updatedReview.reviewedGoals,
        "metaData.updatedAt": updatedReview.metaData?.updatedAt,
      });
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _deleteReview = (id: string) => {
  return new Promise<void>((resolve, reject) => {
    deleteDoc(doc(db, Model.REVIEWS, id))
      .then(() => {
        resolve();
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
