import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import React from "react";

type GenericSnackBarViewProps = {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  onClose: () => void;
  action?: {
    label: string;
    onClick: () => void;
  };
};

const GenericSnackBarView: React.FC<GenericSnackBarViewProps> = ({
  open,
  message,
  severity,
  onClose,
  action,
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      key={message}
      open={open}
      autoHideDuration={severity === "error" ? null : 6000}
      onClose={onClose}
      action={
        action && (
          <Button color="secondary" size="small" onClick={action.onClick}>
            {action.label}
          </Button>
        )
      }
    >
      <SnackbarContent
        message={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {severity === "success" && (
              <CheckCircleIcon
                sx={{
                  color: "success.main",
                }}
              />
            )}
            {severity === "error" && (
              <ErrorIcon
                sx={{
                  color: "error.main",
                }}
              />
            )}
            {severity === "warning" && (
              <ErrorIcon
                sx={{
                  color: "warning.main",
                }}
              />
            )}
            {severity === "info" && <InfoIcon />}
            <Box sx={{ ml: 2 }}>{message}</Box>
          </Box>
        }
      />
    </Snackbar>
  );
};

export default GenericSnackBarView;
