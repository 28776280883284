import JournalEntryEditorController from "../components/journal/journal_entry/JournalEntryEditorController";
import PageContainer from "../components/layout/PageContainer";

const JournalEntry = () => {
  return (
    <PageContainer>
      <JournalEntryEditorController />
    </PageContainer>
  );
};

export default JournalEntry;
