import { GoalFilterType } from "@beyondrealityapp/core/goal/types";
import { useEffect, useState } from "react";

export const useGoalsFilter = (
  defaultFilter: GoalFilterType,
  listId: string
) => {
  const [filter, setFilter] = useState<GoalFilterType>(defaultFilter);

  useEffect(() => {
    localStorage.getItem(`goalsfilter/${listId}`) &&
      setFilter(JSON.parse(localStorage.getItem(`goalsfilter/${listId}`) as string));
  }, [listId]);

  const setGoalsFilter = (newFilter: GoalFilterType) => {
    setFilter(newFilter);
    localStorage.setItem(`goalsfilter/${listId}`, JSON.stringify(newFilter));
  };

  return { filter, setGoalsFilter };
};
