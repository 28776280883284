import { Page } from "@beyondrealityapp/core/shared/constants";
import ReviewsListView from "./ReviewsListView";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import {
  useFetchReviewsQuery,
  useDeleteReviewMutation,
} from "../../../api/review";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const ReviewListController = () => {
  const [page, setPage] = useState<number>(1);
  const {
    data: reviews = [],
    isFetching,
    isLoading,
    isError,
    error,
  } = useFetchReviewsQuery({
    page,
  });
  const [deleteReview] = useDeleteReviewMutation();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      const hasMore = reviews?.length === 10 * page;
      if (scrolledToBottom && !isFetching && hasMore) {
        setPage(page + 1);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching, reviews]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleEditClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/${Page.REVIEWS}/${id}/0`);
  };

  const handleDeleteClick = (id: string) => {
    setDeleting(true);
    deleteReview(id).then(() => {
      dispatch(
        showSnackbar({
          message: "Review succesfully deleted",
          severity: Severity.SUCCESS,
        })
      );
    });
  };

  const handleOpenGoalClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/${Page.GOALS}/${id}`, {
      state: { previousLocation: location },
    });
  };

  return (
    <ReviewsListView
      expanded={expanded}
      loading={isLoading}
      error={isError}
      errorMessage={error as string}
      deleting={deleting}
      reviews={reviews}
      handleChange={handleChange}
      handleEditClick={handleEditClick}
      handleDeleteClick={handleDeleteClick}
      handleOpenGoalClick={handleOpenGoalClick}
    />
  );
};

export default ReviewListController;
