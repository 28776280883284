import { CoverImage } from "@beyondrealityapp/core/shared/types";
import ImageGridView from "./ImageGridView";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type ImageGridControllerProps = {
  images: CoverImage[];
  onImageClickHandler: (image: CoverImage) => void;
};

const ImageGridController: React.FC<ImageGridControllerProps> = ({
  images,
  onImageClickHandler,
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const columns = sm ? 2 : 3;
  const gap = sm ? 2 : 4;

  return (
    <ImageGridView
      images={images}
      onImageClickHandler={onImageClickHandler}
      columns={columns}
      gap={gap}
    />
  );
};

export default ImageGridController;
