import { auth } from "../../config/firebase";
import { Navigate, Outlet } from "react-router-dom";
import { Page } from "@beyondrealityapp/core/shared/constants";

const RedirectSignInUpRoute: React.FC = () => {
  const user = auth.currentUser;
  if (user) {
    return <Navigate replace to={Page.GOALS} />;
  }
  return <Outlet />;
};

export default RedirectSignInUpRoute;
