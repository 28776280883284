import { auth, db } from "../../../config/firebase";
import {
  GoalFilterType,
  GoalPeriodType,
  GoalStatusType,
} from "@beyondrealityapp/core/goal/types";
import ListFilterView from "./ListFilterView";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { useEffect, useState } from "react";
import { useFetchUsers } from "../../../hooks/useUser";

type ListFilterControllerProps = {
  filter: GoalFilterType;
  setFilter: (filter: GoalFilterType) => void;
};

const ListFilterController: React.FC<ListFilterControllerProps> = ({
  filter,
  setFilter,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [themeFilterOptons, setThemeFilterOptons] = useState<string[]>([]);
  const { fetchUser } = useFetchUsers(db, auth);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    fetchUser(uid).then((user) => {
      if (user) {
        setThemeFilterOptons(user.themes);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusFilterOptions: GoalStatusType[] = [
    "Not started",
    "In progress",
    "Completed",
    "Overdue",
  ];
  const periodFilterOptions: GoalPeriodType[] = [
    "Today",
    "This week",
    "This month",
    "This quarter",
    "This year",
  ];

  const statusChipDeleteHandler = (value: GoalStatusType) => {
    setFilter({
      ...filter,
      status: filter.status.filter((item) => item !== value),
    });
  };
  const periodChipDeleteHandler = (value: GoalPeriodType) => {
    setFilter({
      ...filter,
      period: filter.period.filter((item) => item !== value),
    });
  };
  const themeChipDeleteHandler = (value: string) => {
    setFilter({
      ...filter,
      theme: filter.theme.filter((item) => item !== value),
    });
  };

  const handleStatusChange = (event: SelectChangeEvent<string[]>) => {
    const status = event.target.value as GoalStatusType[];
    setFilter({ ...filter, status });
  };
  const handlePeriodChange = (event: SelectChangeEvent<string[]>) => {
    const period = event.target.value as GoalPeriodType[];
    setFilter({ ...filter, period });
  };
  const handleThemeChange = (event: SelectChangeEvent<string[]>) => {
    const theme = event.target.value as string[];
    setFilter({ ...filter, theme });
  };
  const handleShowChildrenChange = (showChildren: boolean) => {
    setFilter({ ...filter, showChildren });
  }; 

  const toggleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <ListFilterView
      filter={filter}
      open={open}
      toggleOpen={toggleOpen}
      handleStatusChange={handleStatusChange}
      statusChipDeleteHandler={statusChipDeleteHandler}
      statusFilterOptions={statusFilterOptions}
      handlePeriodChange={handlePeriodChange}
      periodChipDeleteHandler={periodChipDeleteHandler}
      periodFilterOptions={periodFilterOptions}
      handleThemeChange={handleThemeChange}
      themeChipDeleteHandler={themeChipDeleteHandler}
      themeFilterOptons={themeFilterOptons}
      handleShowChildrenChange={handleShowChildrenChange}
    />
  );
};

export default ListFilterController;
