import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import { Page } from "@beyondrealityapp/core/shared/constants";
import PersonalityTestDetails from "./PersonalityTestDetails";
import { PersonalityTestType } from "@beyondrealityapp/core/personality_test/types";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router";
import useUserRole from "../../hooks/useUserRole";

type PersonalityTestProps = {
  test: PersonalityTestType;
  expanded: string | false;
  handleChange: (
    id: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const PersonalityTest: React.FC<PersonalityTestProps> = ({
  test,
  expanded,
  handleChange,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useUserRole();
  // When expanded is true and userGenerated is true
  // OR expanded is true, userGenerated is false and userRole == admin
  const showEditButton =
    (expanded === test.id && test.userGenerated) ||
    (expanded === test.id &&
      !test.userGenerated &&
      test.status === "template" &&
      userRole === "admin");

  const handleEditClick = (event: any) => {
    event.stopPropagation();
    navigate(`/${Page.PERSONALITY_TEST}/${test.id}`, {
      state: { previousLocation: location },
    });
  };

  return (
    <Accordion
      sx={{ width: "100%" }}
      expanded={expanded === test.id}
      onChange={handleChange(test.id)}
      id={test.id}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${test.id}`}
        id={`${test.id}-header`}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginRight: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {test.status === "completed" ? (
                <TaskAltIcon color="success" />
              ) : (
                <RadioButtonUncheckedIcon color="action" />
              )}
            </Box>
            <Typography>{test.name}</Typography>
          </Box>
          <Fade in={showEditButton}>
            <IconButton
              aria-label="edit"
              color="default"
              onClick={handleEditClick}
            >
              <EditIcon />
            </IconButton>
          </Fade>
        </Box>
      </AccordionSummary>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <PersonalityTestDetails personalityTest={test} />
      </Box>
    </Accordion>
  );
};

export default PersonalityTest;
