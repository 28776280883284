import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { FormikProps } from "formik";
import GoalCardController from "../../goals/goal_card/GoalCardController";
import LoadingSpinner from "../../layout/LoadingSpinner";
import { ReviewType } from "@beyondrealityapp/core/review/types";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";

const likertScale = [
  {
    value: 1,
    label: "Very dissatisfied",
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 3,
    label: "Neutral",
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 5,
    label: "Very satisfied",
  },
];

type ReviewFlowViewProps = {
  formik: FormikProps<ReviewType>;
  formRef: React.RefObject<HTMLFormElement>;
  currentGoalIndex: number;
  goal?: GoalClass;
  isFetchGoalError: boolean;
  fetchGoalError: string;
  loadingGoal: boolean;
  submitError: string;
  handleNextClick: () => void;
  handleBackClick: () => void;
};

const ReviewFlowView: React.FC<ReviewFlowViewProps> = ({
  formik,
  formRef,
  currentGoalIndex,
  goal,
  isFetchGoalError,
  fetchGoalError,
  loadingGoal,
  submitError,
  handleNextClick,
  handleBackClick,
}) => {
  return (
    <Box>
      <Typography variant="overline" sx={{ marginY: 2 }}>
        Review {currentGoalIndex + 1} of {formik.values.reviewedGoals.length}
      </Typography>
      {!loadingGoal && goal && <GoalCardController goal={goal} />}
      {!loadingGoal && !goal && isFetchGoalError && (
        <Alert severity="error">
          An error occurred while fetching the goal: {fetchGoalError}
        </Alert>
      )}
      {loadingGoal && !isFetchGoalError && <LoadingSpinner />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 2,
          marginX: 4,
        }}
        onSubmit={formik.handleSubmit}
        component="form"
        ref={formRef}
      >
        {formik.values.reviewedGoals[currentGoalIndex]?.oldCurrent && (
          <>
            <Typography variant="h6">What is your current progress?</Typography>
            <TextField
              id={`newCurrent-${currentGoalIndex}`}
              name={`reviewedGoals[${currentGoalIndex}].newCurrent`}
              label="Current"
              fullWidth
              margin="normal"
              value={
                formik.values.reviewedGoals[currentGoalIndex]?.newCurrent ?? ""
              }
              placeholder={
                formik.values.reviewedGoals[currentGoalIndex]?.oldCurrent ?? ""
              }
              helperText={`Target: ${formik.values.reviewedGoals[currentGoalIndex]?.target} ${formik.values.reviewedGoals[currentGoalIndex]?.kpi}`}
              onChange={formik.handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "background.paper",
                },
                marginBottom: 2,
              }}
            />
          </>
        )}
        <Typography variant="h6">
          How satisfied are you with your progress?
        </Typography>
        <Slider
          id={`statisfaction-${currentGoalIndex}`}
          name={`reviewedGoals[${currentGoalIndex}].satisfaction`}
          aria-label="progress-satisfaction"
          defaultValue={3}
          step={1}
          marks={likertScale}
          min={1}
          max={5}
          valueLabelDisplay="auto"
          value={
            formik.values.reviewedGoals[currentGoalIndex]?.satisfaction ?? 3
          }
          onChange={formik.handleChange}
          sx={{
            '& .MuiSlider-markLabel[data-index="0"]': {
              transform: "translateX(0%)",
            },
            '& .MuiSlider-markLabel[data-index="4"]': {
              transform: "translateX(-100%)",
            },
          }}
        />
        <TextField
          id={`motivation-${currentGoalIndex}`}
          name={`reviewedGoals[${currentGoalIndex}].motivation`}
          label="Why did you give this score?"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          value={
            formik.values.reviewedGoals[currentGoalIndex]?.motivation ?? ""
          }
          onChange={formik.handleChange}
          sx={{
            backgroundColor: "background.paper",
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            disabled={currentGoalIndex === 0}
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button variant="contained" onClick={handleNextClick}>
            {currentGoalIndex + 1 < formik.values.reviewedGoals.length
              ? "Next"
              : "Submit"}
          </Button>
        </Box>
        <Collapse
          in={submitError !== ""}
          sx={{
            marginTop: 2,
          }}
        >
          <Alert severity="error">{submitError}</Alert>
        </Collapse>
      </Box>
    </Box>
  );
};

export default ReviewFlowView;
