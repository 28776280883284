import PageContainer from "../components/layout/PageContainer";
import PersonalityTestsOverview from "../components/personality_tests/PersonalityTestsOverview";

const PersonalityTests = () => {
  return (
    <PageContainer>
        <PersonalityTestsOverview />
    </PageContainer>
  );
};

export default PersonalityTests;
