import JournalEntriesListView from "./JournalEntriesListView";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import {
  useFetchJournalEntriesQuery,
  useDeleteJournalEntryMutation,
} from "../../../api/journal";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const JournalEntriesListController = () => {
  const dispatch = useDispatch();
  const { isXs } = useBreakpoints();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [page, setPage] = useState<number>(1);
  const [deleting, setDeleting] = useState<boolean>(false);
  const {
    data: journalEntries,
    isFetching,
    isLoading,
    isError,
    error,
  } = useFetchJournalEntriesQuery({ page: page });
  const [deleteJournalEntry] = useDeleteJournalEntryMutation();

  const navigate = useNavigate();

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      const hasMore = journalEntries?.length === 10 * page;
      if (scrolledToBottom && !isFetching && hasMore) {
        setPage(page + 1);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching, journalEntries]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleEditClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/${Page.JOURNAL}/${id}`);
  };

  const handleDeleteClick = (id: string) => {
    setDeleting(true);
    deleteJournalEntry(id)
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Journal entry successfully deleted",
            severity: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error.message,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <JournalEntriesListView
      isXs={isXs}
      expanded={expanded}
      loading={isLoading}
      error={isError}
      errorMessage={error as string}
      deleting={deleting}
      journalEntries={journalEntries || []}
      handleChange={handleChange}
      handleEditClick={handleEditClick}
      handleDeleteClick={handleDeleteClick}
    />
  );
};

export default JournalEntriesListController;
