import CardImageHeaderView from "./CardImageHeaderView";
import { OnlineContext } from "../../../providers/OnlineProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useLoadImage } from "../../../hooks/useLoadImage";

type CardImageHeaderControllerProps = {
  imageUrl: string;
  verticalOffset: number;
  text?: string;
  imageDeleteHandler?: () => void;
  offSetHandler?: (offset: number) => void;
};

const CardImageHeaderController: React.FC<CardImageHeaderControllerProps> = ({
  imageUrl,
  text,
  verticalOffset,
  offSetHandler,
  imageDeleteHandler,
}) => {
  const { image, loading } = useLoadImage(imageUrl, 1080);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const isOnline = useContext(OnlineContext);
  const isDragging = useRef(false);
  const startY = useRef(0);
  const startOffset = useRef(0);

  useEffect(() => {
    if (!isOnline) {
      setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  const deleteHandler = () => {
    setCollapsed(false);
    if (imageDeleteHandler) {
      setTimeout(imageDeleteHandler, 500);
    }
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    isDragging.current = true;
    startY.current = e.clientY;
    startOffset.current = verticalOffset;
    if (offSetHandler) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    isDragging.current = true;
    startY.current = e.touches[0].clientY;
    startOffset.current = verticalOffset;
    if (offSetHandler) {
      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onTouchEnd);
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    if (isDragging.current) {
      const dy = -(e.clientY - startY.current);
      if (offSetHandler)
        offSetHandler(Math.max(0, Math.min(100, startOffset.current + dy)));
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    if (isDragging.current) {
      const dy = -(e.touches[0].clientY - startY.current);
      if (offSetHandler)
        offSetHandler(Math.max(0, Math.min(100, startOffset.current + dy)));
    }
  };

  const stopDragging = () => {
    isDragging.current = false;
    if (offSetHandler) {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);

      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    }
  };

  const onMouseUp = stopDragging;
  const onTouchEnd = stopDragging;

  return (
    <CardImageHeaderView
      image={image}
      loading={loading}
      collapsed={collapsed}
      status={text}
      imageVerticalOffset={verticalOffset}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      offSetHandler={offSetHandler}
      imageDeleteHandler={imageDeleteHandler}
      deleteHandler={deleteHandler}
    />
  );
};

export default CardImageHeaderController;
