import { Auth } from "@firebase/auth";
import {
  deleteObject,
  FirebaseStorage,
  ref,
  getDownloadURL,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import FileResizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

export const useImageUpload = (storage: FirebaseStorage, auth: Auth) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isCompressing, setIsCompressing] = useState<boolean>(false);

  const uploadImage = async (file: File) => {
    return new Promise((resolve, reject) => {
      setIsUploading(true);
      setIsCompressing(true);
      const imageStorageRef = ref(
        storage,
        `images/${auth.currentUser?.uid}/${uuidv4()}`
      );
      FileResizer.imageFileResizer(
        file,
        500,
        500,
        "WEBP",
        100,
        0,
        (uri) => {
          setIsCompressing(false);
          _persistImage(uri as File, imageStorageRef)
            .then((url) => {
              resolve(url);
            })
            .catch((error) => {
              reject(error.message);
            })
            .finally(() => {
              setIsUploading(false);
            });
        },
        "file"
      );
    });
  };

  const deleteImage = async (imageUrl: string) => {
    return new Promise((resolve, reject) => {
      const imageRef = ref(storage, imageUrl);
      deleteObject(imageRef)
        .then(() => {
          resolve("Image deleted");
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const _persistImage = async (
    fileToUpload: File,
    imageStorageRef: StorageReference
  ) => {
    const snapshot = await uploadBytes(imageStorageRef, fileToUpload);
    const downloadUrl = await getDownloadURL(snapshot.ref);
    return downloadUrl;
  };

  return {
    isUploading,
    isCompressing,
    deleteImage,
    uploadImage,
  };
};
