import ThemeCardView from "./ThemeCardView";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";
import { useState } from "react";

type ThemeCardControllerProps = {
  theme: ThemeClass;
};

const ThemeCardController: React.FC<ThemeCardControllerProps> = ({ theme }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const onClickHandler = () => {
    setModalIsOpen(true);
  };
  const onCloseHandler = () => {
    setModalIsOpen(false);
  };

  return (
    <ThemeCardView
      theme={theme}
      modalIsOpen={modalIsOpen}
      onClickHandler={onClickHandler}
      onCloseHandler={onCloseHandler}
    />
  );
};

export default ThemeCardController;
