import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

type DefaultModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const DefaultModal: React.FC<DefaultModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <Card
        sx={{
          width: {
            lg: "50%",
            md: "75%",
            xs: "100%",
          },
          maxWidth: "100%",
          maxHeight: "100%",
          overflowX: "hidden",
          overflowY: "auto",   
        }}
      >
        {children}
      </Card>
    </Modal>
  );
};

export default DefaultModal;