import { useEffect, useState } from "react";
import { auth } from "../config/firebase";

const useUserRole = () => {
  const [role, setRole] = useState<"admin" | "user" | null>(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      if (idTokenResult?.claims.admin) {
        setRole("admin");
      } else {
        setRole("user");
      }
    };

    fetchUserRole();
  }, []);

  return role;
};

export default useUserRole;
