import Box from "@mui/material/Box";
import JournalEntriesListController from "../components/journal/journal_entries_list/JournalEntriesListController";
import NewJournalEntryButtonController from "../components/journal/new_journal_entry_button/NewJournalEntryButtonController";
import PageContainer from "../components/layout/PageContainer";
import Typography from "@mui/material/Typography";

const Journal = () => {
  return (
    <PageContainer>
      <Box
        sx={{
          height: "200px",
          width: "200px",
        }}
      >
        <NewJournalEntryButtonController />
      </Box>
      <Typography variant="h6" sx={{ marginY: 2 }}>
        Journal Entries
      </Typography>
      <JournalEntriesListController />
    </PageContainer>
  );
};

export default Journal;
