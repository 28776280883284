import PageContainer from "../components/layout/PageContainer";
import ThemesListController from "../components/themes/themes_list/ThemesListController";

const Themes = () => {
  return (
    <PageContainer>
      <ThemesListController />
    </PageContainer>
  );
};

export default Themes;
