import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

type CardImageHeaderViewProps = {
  image: string;
  imageVerticalOffset: number;
  loading: boolean;
  collapsed: boolean;
  status?: string;
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
  onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void;
  imageDeleteHandler?: () => void;
  offSetHandler?: (offset: number) => void;
  deleteHandler: () => void;
};  

const CardImageHeaderView: React.FC<CardImageHeaderViewProps> = ({
  image,
  imageVerticalOffset,
  loading,
  collapsed,
  status,
  onMouseDown,
  onTouchStart,
  offSetHandler,
  imageDeleteHandler,
  deleteHandler,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Collapse in={collapsed}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <CardMedia
            component="img"
            height="100"
            image={image}
            alt="Goal Image"
            sx={{
              cursor: offSetHandler ? "ns-resize" : "pointer",
              objectFit: "cover",
              objectPosition: "50% " + imageVerticalOffset + "%",
            }}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
          />
          {status ? (
            <Box sx={{ position: "absolute", top: 8, left: 8 }}>
              <Chip
                label={status}
                size="small"
                color={
                  status === "Completed"
                    ? "success"
                    : status === "In progress"
                    ? "info"
                    : status === "Not started"
                    ? "primary"
                    : status === "Overdue"
                    ? "error"
                    : undefined
                }
              />
            </Box>
          ) : null}
          {loading ? (
            <Typography
              variant="body2"
              align="center"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Loading...
            </Typography>
          ) : null}
        </Box>
      </Collapse>
      {imageDeleteHandler && image ? (
        <IconButton
          aria-label="delete-image"
          onClick={deleteHandler}
          sx={{
            position: "absolute",
            top: 4,
            right: 4,
            color: "primary.main",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              color: "error.main",
            },
          }}
        >
          <Delete />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default CardImageHeaderView;
