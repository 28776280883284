import { useEffect, useState, createContext } from "react";

type ConnectivityStatusProps = {
  children: React.ReactNode;
};

export const OnlineContext = createContext(true);

const OnlineProvider: React.FC<ConnectivityStatusProps> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <OnlineContext.Provider value={isOnline}>{children}</OnlineContext.Provider>
  );
};

export default OnlineProvider;
