import { Alert } from "@mui/material";
import { auth } from "../../config/firebase";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { object, string } from "yup";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import TextField from "@mui/material/TextField";

const UserSchema = object({
  email: string().required("Please make sure to add an email"),
  password: string()
    .required("Please make sure to add a password")
    .min(8, "Password must be at least 8 characters long"),
  passwordConfirm: string()
    .required("Please confirm your password")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const initialValues = {
  email: "",
  password: "",
  passwordConfirm: "",
  recaptcha: "",
};

export const SignUpForm = () => {
  const [error, setError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const signUpHandler = async (values: any) => {
    setIsSubmitting(true);
    setError("");
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(() => {
        if (!auth.currentUser) return;
        sendEmailVerification(auth.currentUser);
        signInWithEmailAndPassword(auth, values.email, values.password).then(
          (userCredential) => {
            const user = userCredential.user;
            if (user) {
              navigate(Page.HOME);
            }
          }
        );
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    onSubmit: signUpHandler,
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={formik.handleChange}
        value={formik.values.email}
        helperText={
          formik.touched.email && (formik.errors.email as React.ReactNode)
        }
        error={formik.touched.email && Boolean(formik.errors.email)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={formik.handleChange}
        value={formik.values.password}
        helperText={
          formik.touched.password && (formik.errors.password as React.ReactNode)
        }
        error={formik.touched.password && Boolean(formik.errors.password)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="passwordConfirm"
        label="Confirm Password"
        type="password"
        id="passwordConfirm"
        autoComplete="current-password"
        onChange={formik.handleChange}
        value={formik.values.passwordConfirm}
        helperText={
          formik.touched.passwordConfirm &&
          (formik.errors.passwordConfirm as React.ReactNode)
        }
        error={
          formik.touched.passwordConfirm &&
          Boolean(formik.errors.passwordConfirm)
        }
      />
      <Collapse in={Boolean(error)}>
        <Alert severity="error">{error}</Alert>
      </Collapse>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        endIcon={
          isSubmitting ? <CircularProgress size={20} color="inherit" /> : null
        }
      >
        Sign Up
      </Button>
    </Box>
  );
};
