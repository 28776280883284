import AdsClickRounded from "@mui/icons-material/AdsClickRounded";
import Collapse from "@mui/material/Collapse";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import ScaleRounded from "@mui/icons-material/ScaleRounded";
import TextField from "@mui/material/TextField";
import TimerRounded from "@mui/icons-material/TimerRounded";

type GoalCardKPIProps = {
  formik: FormikState<Partial<GoalFormType>> & FormikHelpers<Partial<GoalFormType>> & FormikHandlers;
  collapsed: boolean;
};

const GoalCardKPI: React.FC<GoalCardKPIProps> = ({ collapsed, formik }) => {
  return (
    <Collapse in={collapsed}>
      <Grid container columnSpacing={1} marginTop={2}>
        <Grid item xs={4}>
          <TextField
            id="current"
            name="current"
            label="Current"
            variant="standard"
            placeholder="0"
            value={formik.values.current}
            onChange={formik.handleChange}
            error={formik.touched.current && Boolean(formik.errors.current)}
            helperText={formik.touched.current && formik.errors.current}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ScaleRounded fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="target"
            name="target"
            label="Target"
            variant="standard"
            placeholder="100"
            value={formik.values.target}
            onChange={formik.handleChange}
            error={formik.touched.target && Boolean(formik.errors.target)}
            helperText={formik.touched.target && formik.errors.target}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AdsClickRounded fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="kpi"
            name="kpi"
            label="KPI"
            variant="standard"
            placeholder="%"
            value={formik.values.kpi}
            onChange={formik.handleChange}
            error={formik.touched.kpi && Boolean(formik.errors.kpi)}
            helperText={formik.touched.kpi && formik.errors.kpi}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TimerRounded fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default GoalCardKPI;
