import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import DeleteButton from "../../layout/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import LoadingSpinner from "../../layout/LoadingSpinner";
import { ReviewClass } from "@beyondrealityapp/core/review/classes";
import Typography from "@mui/material/Typography";

type ReviewsListViewProps = {
  expanded: string | false;
  loading: boolean;
  deleting: boolean;
  error: boolean;
  errorMessage: string;
  reviews: ReviewClass[];
  handleChange: (
    panel: string
  ) => (event: React.MouseEvent, isExpanded: boolean) => void;
  handleEditClick: (event: any, id: string) => void;
  handleDeleteClick: (id: string) => void;
  handleOpenGoalClick: (event: any, id: string) => void;
};

const ReviewsListView: React.FC<ReviewsListViewProps> = ({
  expanded,
  loading,
  deleting,
  error,
  errorMessage,
  reviews,
  handleChange,
  handleEditClick,
  handleDeleteClick,
  handleOpenGoalClick,
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading &&
        reviews.map((review) => (
          <Accordion
            sx={{ width: "100%" }}
            expanded={expanded === review.id}
            onChange={handleChange(review.id ?? "")}
            id={review.id}
            key={review.id}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {review.type.charAt(0).toUpperCase() + review.type.slice(1)}{" "}
                  review
                </Typography>
                <Typography variant="body2" marginRight={2}>
                  {review.createdAtToDateTimeString()}
                </Typography>
                <Fade in={expanded === review.id}>
                  <IconButton
                    aria-label="edit"
                    color="default"
                    onClick={(event) => handleEditClick(event, review.id ?? "")}
                  >
                    <EditIcon />
                  </IconButton>
                </Fade>
              </Box>
            </AccordionSummary>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AccordionDetails
                sx={{
                  width: {
                    sx: "100%",
                    md: "80%",
                  },
                }}
              >
                {review.reviewedGoals.map((goal) => (
                  <Box key={goal.id} marginBottom={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h6"
                        key={goal.id}
                        marginTop={2}
                        color="primary"
                      >
                        {goal.content}
                      </Typography>
                      <IconButton
                        aria-label="open"
                        color="default"
                        onClick={(event) =>
                          handleOpenGoalClick(event, goal.id ?? "")
                        }
                      >
                        <LaunchIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          marginTop: 1,
                          marginRight: 1,
                        }}
                      >
                        Progress:{" "}
                      </Typography>
                      <Chip
                        label={goal.satisfaction}
                        size="small"
                        color="primary"
                      />
                    </Box>
                    {goal.motivation && (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            marginTop: 1,
                          }}
                        >
                          Motivation:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            marginLeft: 2,
                          }}
                        >
                          {goal.motivation}
                        </Typography>
                      </>
                    )}
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <DeleteButton
                    deleteHandler={() => handleDeleteClick(review.id ?? "")}
                    deleting={deleting}
                  />
                </Box>
              </AccordionDetails>
            </Box>
          </Accordion>
        ))}

      {reviews.length === 0 && !error && !loading && (
        <Typography variant="body1" gutterBottom>
          No reviews have been created yet..
        </Typography>
      )}
      {!loading && error && (
        <>
          <Typography variant="body1" gutterBottom>
            An error occured while trying to retrieve your reviews:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
            }}
          >
            {errorMessage}
          </Typography>
        </>
      )}
    </>
  );
};

export default ReviewsListView;
