import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardImageHeaderController from "../../image/card_image_header/CardImageHeaderController";
import ThemeModalController from "../theme_modal/ThemeModalController";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";
import Typography from "@mui/material/Typography";

export type ThemeCardViewProps = {
  theme: ThemeClass;
  modalIsOpen: boolean;
  onCloseHandler: () => void;
  onClickHandler: () => void;
};

const ThemeCardView: React.FC<ThemeCardViewProps> = ({
  theme,
  modalIsOpen,
  onClickHandler,
  onCloseHandler,
}) => {
  return (
    <>
      <Card
        sx={{ width: "100%", height: "100%", cursor: "pointer" }}
        onClick={onClickHandler}
      >
        {theme.image && (
          <CardImageHeaderController
            imageUrl={theme.image}
            verticalOffset={theme.imageVerticalOffset ?? 50}
          />
        )}
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {theme.name}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {theme.description.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </Typography>
        </CardContent>
      </Card>
      {modalIsOpen && (
        <ThemeModalController
          theme={theme}
          isOpen={modalIsOpen}
          onClose={onCloseHandler}
        />
      )}
    </>
  );
};

export default ThemeCardView;
