import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonBase from "@mui/material/ButtonBase";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import LoadingSpinner from "./LoadingSpinner";
import Typography from "@mui/material/Typography";

const AddButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "transparent",
  transition: "background-color 0.3s ease",
  "&:hover, &.Mui-focusVisible": {
    backgroundColor: theme.palette.action.hover,
  },
}));

type CardButtonProps = {
  title: string;
  description?: string;
  loading?: boolean;
  onClick: () => void;
};

const CardButton: React.FC<CardButtonProps> = ({
  title,
  description,
  loading,
  onClick,
}) => {
  return (
    <>
      <Card sx={{ width: "100%", height: "100%" }}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <AddButton onClick={onClick}>
            <AddCircleOutlineIcon color="action" fontSize="large" />
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ marginTop: 2 }}
            >
              {title}
            </Typography>
            {description && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginX: 2,
                }}
              >
                {description}
              </Typography>
            )}
          </AddButton>
        )}
      </Card>
    </>
  );
};

export default CardButton;
