import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import DeleteButton from "../../layout/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import he from "he";
import IconButton from "@mui/material/IconButton";
import LoadingSpinner from "../../layout/LoadingSpinner";
import Markdown from "markdown-to-jsx";
import { JournalEntryClass } from "@beyondrealityapp/core/journal/classes";
import Typography from "@mui/material/Typography";

type JournalEntriesListViewProps = {
  isXs: boolean;
  expanded: string | false;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  deleting: boolean;
  journalEntries: JournalEntryClass[];
  handleChange: (
    panel: string
  ) => (event: React.MouseEvent, isExpanded: boolean) => void;
  handleEditClick: (event: any, id: string) => void;
  handleDeleteClick: (id: string) => void;
};

const JournalEntriesListView: React.FC<JournalEntriesListViewProps> = ({
  isXs,
  expanded,
  loading,
  error,
  errorMessage,
  deleting,
  journalEntries,
  handleChange,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading &&
        !error &&
        !errorMessage &&
        journalEntries.map((journalEntry) => (
          <Accordion
            sx={{ width: "100%" }}
            expanded={expanded === journalEntry.id}
            onChange={handleChange(journalEntry.id ?? "")}
            id={journalEntry.id}
            key={journalEntry.id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    marginRight={2}
                    align="center"
                    noWrap
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {journalEntry.metaData.createdAt?.toLocaleString("en-Gb", {
                      month: "short",
                    }) ?? ""}{" "}
                    {journalEntry.metaData.createdAt?.toLocaleString("en-Gb", {
                      day: "2-digit",
                    }) ?? ""}
                  </Typography>
                  <Typography variant="body2" marginRight={2}>
                    {journalEntry.metaData.createdAt?.toLocaleString("en-Gb", {
                      year: "numeric",
                    }) ?? ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: isXs ? "150px" : "250px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: journalEntry.title ? "bold" : "normal",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontStyle: journalEntry.title ? "normal" : "italic",
                    }}
                  >
                    {journalEntry.title
                      ? he.decode(journalEntry.title)
                      : "No title available"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontStyle: journalEntry.subtitle ? "normal" : "italic",
                    }}
                  >
                    {journalEntry.subtitle
                      ? he.decode(journalEntry.subtitle)
                      : "No subtitle available"}
                  </Typography>
                </Box>
              </Box>
              <Fade in={expanded === journalEntry.id}>
                <IconButton
                  aria-label="edit"
                  color="default"
                  onClick={(event) =>
                    handleEditClick(event, journalEntry.id ?? "")
                  }
                >
                  <EditIcon />
                </IconButton>
              </Fade>
            </AccordionSummary>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <AccordionDetails
                sx={{
                  width: "80%",
                }}
              >
                <Markdown>
                  {journalEntry.content
                    ? he.decode(journalEntry.content)
                    : "No content available"}
                </Markdown>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <DeleteButton
                    deleteHandler={() =>
                      handleDeleteClick(journalEntry.id ?? "")
                    }
                    deleting={deleting}
                  />
                </Box>
              </AccordionDetails>
            </Box>
          </Accordion>
        ))}

      {journalEntries.length === 0 && !loading && !error && (
        <Typography variant="body1" gutterBottom>
          No journal entries have been created yet..
        </Typography>
      )}
      {!loading && error && (
        <>
          <Typography variant="body1" gutterBottom>
            An error occured while trying to retrieve the journal entries
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
            }}
          >
            {errorMessage}
          </Typography>
        </>
      )}
    </>
  );
};

export default JournalEntriesListView;
