import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Markdown from "markdown-to-jsx";
import MenuHeader from "../MenuHeader";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

type InfoSidebarViewProps = {
  open: boolean;
  markdownContent: string;
  toggleDrawer: () => void;
  isXs: boolean;
};

const InfoSidebarView = ({
  open,
  markdownContent,
  toggleDrawer,
  isXs,
}: InfoSidebarViewProps) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "space-between", sm: "flex-start  " },
          height: "100%",
        }}
      >
        {!isXs && (
          <Box>
            <MenuHeader toggleDrawer={toggleDrawer} />
            <Divider />
          </Box>
        )}
        <Box
          m={2}
          sx={{
            minWidth: "300px",
            maxWidth: "400px",
            overflowY: "auto",
          }}
        >
          <Markdown>{markdownContent}</Markdown>
        </Box>
        {isXs && (
          <Box>
            <Divider />
            <MenuHeader toggleDrawer={toggleDrawer} />
          </Box>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default InfoSidebarView;
