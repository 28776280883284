import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import TextField from "@mui/material/TextField";

type GoalCardContentProps = {
  formik: FormikState<Partial<GoalFormType>> &
    FormikHelpers<Partial<GoalFormType>> &
    FormikHandlers;
};

const GoalCardContent: React.FC<GoalCardContentProps> = ({ formik }) => {
  return (
    <TextField
      autoFocus
      id="goal-content"
      label="What's your goal?"
      placeholder="Enter the description of your goal here..."
      variant="standard"
      sx={{ flex: 1 }}
      name="content"
      value={formik.values.content}
      onChange={formik.handleChange}
      error={formik.touched.content && Boolean(formik.errors.content)}
      helperText={formik.touched.content && formik.errors.content}
      InputLabelProps={{
        sx: { fontWeight: "500" },
      }}
    />
  );
};

export default GoalCardContent;
