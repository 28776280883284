import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Fade from "@mui/material/Fade";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import InputLabel from "@mui/material/InputLabel";
import { JournalEntryFormType } from "@beyondrealityapp/core/journal/types";
import MarkdownEditor from "../../generic/markdown_editor/MarkdownEditor";
import React from "react";
import SubmitButton from "../../layout/SubmitButton";
import { Typography } from "@mui/material";

type JournalEntryEditorViewProps = {
  formik: FormikState<JournalEntryFormType> &
    FormikHelpers<JournalEntryFormType> &
    FormikHandlers;
  isFetching: boolean;
  onChangeContent: (content: string) => void;
  unsavedChanges: boolean;
  showQuickSaveTip?: boolean;
};

const JournalEntryEditorView: React.FC<JournalEntryEditorViewProps> = ({
  formik,
  isFetching,
  onChangeContent,
  unsavedChanges,
  showQuickSaveTip = true,
}) => {
  return (
    <>
      <MarkdownEditor
        onChange={onChangeContent}
        markdown={formik.values.content}
        key={formik.values.id}
        disabled={isFetching}
        placeholder={isFetching ? "Loading..." : "Write your journal entry"}
      />
      {showQuickSaveTip && (
        <InputLabel
          htmlFor="content"
          sx={{
            mt: 1,
          }}
        >
          Tip: press ctrl+s (pc) or cmd+s (mac) to quick save.
        </InputLabel>
      )}
      <Box
        mt={1}
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: 1,
          }}
        >
          <Fade in={unsavedChanges}>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CircleIcon
                sx={{
                  color: "warning.main",
                  fontSize: "small",
                  mr: 0.5,
                }}
              />
              Unsaved changes
            </Typography>
          </Fade>
          <SubmitButton
            onClick={formik.handleSubmit}
            submitting={formik.isSubmitting}
          />
        </Box>
      </Box>
    </>
  );
};

export default JournalEntryEditorView;
