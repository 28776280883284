import AddImageButton from "./AddImageButton";

type AddImageButtonControllerProps = {
  handleClick: () => void;
};

const AddImageButtonController: React.FC<AddImageButtonControllerProps> = ({
  handleClick,
}) => {
  return <AddImageButton handleClick={handleClick} />;
};

export default AddImageButtonController;
