import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ClearIcon from "@mui/icons-material/Clear";
import { CoverImage } from "@beyondrealityapp/core/shared/types";
import FileUploadButton from "../../generic/FileUploadButton";
import IconButton from "@mui/material/IconButton";
import ImageGridController from "../image_grid/ImageGridController";
import LoadingSpinner from "../../layout/LoadingSpinner";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

type AddImageModalViewProps = {
  open: boolean;
  query: string;
  images: CoverImage[];
  loading: boolean;
  error: string;
  isCompressing: boolean;
  isUploading: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  onClose: () => void;
  onFileChangeHandler: (file: File) => void;
  searchHandler: () => void;
  onTextChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterHandler: (e: any) => void;
  onImageClickHandler: (image: CoverImage) => void;
  onClearClickHandler: () => void;
};

const AddImageModalView: React.FC<AddImageModalViewProps> = ({
  open,
  query,
  images,
  loading,
  error,
  isCompressing,
  isUploading,
  inputRef,
  onClose,
  onFileChangeHandler,
  searchHandler,
  onTextChangeHandler,
  onEnterHandler,
  onImageClickHandler,
  onClearClickHandler,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
      disableRestoreFocus
    >
      <Card
        sx={{
          width: {
            lg: "50%",
            md: "75%",
            xs: "100%",
          },
          height: {
            md: "75%",
            xs: "100%",
          },
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <TextField
              id="search"
              label="Search"
              variant="outlined"
              size="small"
              onChange={onTextChangeHandler}
              value={query}
              inputRef={inputRef}
              sx={{
                width: "100%",
              }}
              onKeyDown={onEnterHandler}
              autoFocus
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{
                      visibility: query ? "visible" : "hidden",
                      marginRight: -1,
                    }}
                    onClick={onClearClickHandler}
                  >
                    <ClearIcon color="inherit" />
                  </IconButton>
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={searchHandler}
              sx={{
                marginLeft: 2,
              }}
            >
              Search
            </Button>
          </Box>
          {loading && !error && <LoadingSpinner />}
          {!loading && !error && images.length > 0 && (
            <ImageGridController
              images={images}
              onImageClickHandler={onImageClickHandler}
            />
          )}
          {images.length === 0 && !loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {error && (
                <>
                  <Typography variant="h6" color="error">
                    An error occurred: {error}. Please try again.
                  </Typography>
                  <br />
                </>
              )}
              <Typography variant="h6" align="center" gutterBottom>
                Enter a search term to find images
              </Typography>
              <Typography variant="h6" align="center" gutterBottom>
                OR{" "}
              </Typography>
              <FileUploadButton
                label={
                  isCompressing
                    ? "Compressing..."
                    : isUploading
                    ? "Uploading..."
                    : "Upload Image"
                }
                onFileChange={onFileChangeHandler}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AddImageModalView;
