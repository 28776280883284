import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import TextField from "@mui/material/TextField";

type GoalCardDescriptonProps = {
  formik: FormikState<Partial<GoalFormType>> & FormikHelpers<Partial<GoalFormType>> & FormikHandlers;
  collapsed: boolean;
};

const GoalCardDescripton: React.FC<GoalCardDescriptonProps> = ({
  formik,
  collapsed,
}) => {
  return (
    <Collapse in={collapsed}>
      <Box sx={{ width: "100%", marginTop: 2 }}>
        <TextField
          id="description"
          name="description"
          label="Description"
          variant="standard"
          placeholder="Describe your goal"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
          fullWidth
          multiline
        />
      </Box>
    </Collapse>
  );
};

export default GoalCardDescripton;
