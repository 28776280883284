import Box from "@mui/material/Box";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Collapse from "@mui/material/Collapse";
import { db, auth } from "../../../config/firebase";
import { collection, where, query, getDocs } from "firebase/firestore";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";

type GoalCardThemesProps = {
  formik: FormikState<Partial<GoalFormType>> & FormikHelpers<Partial<GoalFormType>> & FormikHandlers;
  collapsed: boolean;
};

const GoalCardThemes: React.FC<GoalCardThemesProps> = ({
  formik,
  collapsed,
}) => {
  const [themes, setThemes] = useState<string[]>([]);

  useEffect(() => {
    const q = query(
      collection(db, "users"),
      where("uid", "==", auth.currentUser?.uid)
    );
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().themes.length > 0) {
          setThemes(doc.data().themes.sort());
        }
      });
    });
  }, []);
  return (
    <Collapse in={collapsed}>
      <Box sx={{ marginTop: 1 }}>
        <FormControl
          margin="dense"
          fullWidth
          error={formik.touched.themes && Boolean(formik.errors.themes)}
        >
          <InputLabel id="themes" variant="standard">
            Themes
          </InputLabel>
          <Select
            id="themes"
            name="themes"
            label="Themes"
            variant="standard"
            placeholder="Select themes"
            value={formik.values.themes}
            onChange={formik.handleChange}
            renderValue={(selected) => selected.join(", ")}
            multiple
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {themes.length === 0 && (
              <MenuItem disabled value="">
                Go to "Themes" to add a theme
              </MenuItem>
            )}
            {themes.map((theme) => (
              <MenuItem key={theme} value={theme}>
                <ListItemText primary={theme} />
                {formik.values.themes?.includes(theme) && (
                  <CheckBoxIcon color="primary" fontSize="small" />
                )}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText variant="standard">
            {formik.touched.themes && formik.errors.themes}
          </FormHelperText>
        </FormControl>
      </Box>
    </Collapse>
  );
};

export default GoalCardThemes;
