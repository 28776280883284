import Collapse from "@mui/material/Collapse";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { FormikState, FormikHelpers, FormikHandlers } from "formik";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";

type GoalCardDatesProps = {
  formik: FormikState<Partial<GoalFormType>> &
    FormikHelpers<Partial<GoalFormType>> &
    FormikHandlers;
  collapsed: boolean;
};

const GoalCardDates: React.FC<GoalCardDatesProps> = ({ formik, collapsed }) => {
  const startDateError =
    formik.touched.startDate && Boolean(formik.errors.startDate);
  const endDateError = formik.touched.endDate && Boolean(formik.errors.endDate);
  const startHelperText = formik.touched.startDate && formik.errors.startDate;
  const endHelperText = formik.touched.endDate && formik.errors.endDate;
  const startDate = formik.values.startDate;
  const endDate = formik.values.endDate;

  useEffect(() => {
    if (startDate && !endDate) {
      formik.setFieldValue("endDate", startDate, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Collapse in={collapsed}>
      <Grid container columnSpacing={3} marginTop={1}>
        <Grid item xs={6}>
          <DatePicker
            label="Start Date"
            value={formik.values.startDate}
            onChange={(date: Dayjs) =>
              formik.setFieldValue("startDate", date, true)
            }
            slotProps={{
              textField: {
                name: "startDate",
                id: "startDate",
                variant: "standard",
                error: startDateError,
                helperText: startHelperText,
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="End Date"
            value={formik.values.endDate}
            onChange={(date: Dayjs) =>
              formik.setFieldValue("endDate", date, true)
            }
            slotProps={{
              textField: {
                name: "endDate",
                id: "endDate",
                variant: "standard",
                error: endDateError,
                helperText: endHelperText,
                fullWidth: true,
              },
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default GoalCardDates;
