import { Navigate } from "react-router";
import { Page } from "@beyondrealityapp/core/shared/constants";

const Home = () => {
  const shouldRedirect = true;

  return <>{shouldRedirect && <Navigate to={Page.GOALS} />}</>;
};

export default Home;
