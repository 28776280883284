import { ErrorMessage } from "@beyondrealityapp/core/shared/constants";
import JournalEntryEditorView from "./JournalEntryEditorView";
import { JournalEntryFormType } from "@beyondrealityapp/core/journal/types";
import {
  useCreateUpdateJournalEntryMutation,
  useFetchJournalEntryQuery,
} from "../../../api/journal";
import { object, string } from "yup";
import { showSnackbar } from "../../../features/snackbar";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { useDispatch } from "react-redux";
import useEncryptedStorage from "../../../hooks/useEncryptedStorage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const JournalEntryEditorController: React.FC = () => {
  const { journalEntryId } = useParams();
  const { isXs } = useBreakpoints();
  const dispatch = useDispatch();
  const [createUpdateJournalEntry] = useCreateUpdateJournalEntryMutation();
  const { data, isFetching } = useFetchJournalEntryQuery(journalEntryId ?? "");
  const { getFromEncryptedStorage, setToEncryptedStorage } =
    useEncryptedStorage();
  const [initialValues, setInitialValues] = useState<JournalEntryFormType>({
    id: "",
    content: "",
    title: "",
    subtitle: "",
    metaData: {
      createdAt: undefined,
      updatedAt: undefined,
    },
  });

  const JournalEntrySchema = object({
    content: string().required(),
  });

  const onChangeContent = (content: string) => {
    const updatedAt = new Date().toISOString();
    const journalEntryContent = {
      content,
      updatedAt,
    };
    setToEncryptedStorage(
      `journalEntry/${journalEntryId}/content`,
      JSON.stringify(journalEntryContent)
    );
    formik.setFieldValue("content", content);
  };

  const onSubmit = (values: JournalEntryFormType) => {
    createUpdateJournalEntry(values)
      .then((response) => {
        if ("data" in response && response.data) {
          dispatch(
            showSnackbar({
              message: "Journal entry saved successfully",
              severity: "success",
            })
          );
        } else if ("error" in response) {
          const error = response.error as Error;
          dispatch(
            showSnackbar({
              message: error.message,
              severity: "error",
            })
          );
        } else {
          dispatch(
            showSnackbar({
              message: ErrorMessage.UNKNOW_ERROR,
              severity: "error",
            })
          );
        }
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: JournalEntrySchema,
    onSubmit,
  });

  useEffect(() => {
    const handleSave = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    window.addEventListener("keydown", handleSave);
    return () => window.removeEventListener("keydown", handleSave);
  }, [formik]);

  useEffect(() => {
    const populateForm = async () => {
      const journalEntryContentString = (await getFromEncryptedStorage(
        `journalEntry/${journalEntryId}/content`
      )) as string;
      const journalEntryContent = JSON.parse(journalEntryContentString) as {
        content: string;
        updatedAt: string;
      };
      if (data && !isFetching) {
        const journalEntryData = data.toForm() as JournalEntryFormType;
        setInitialValues({
          content: journalEntryData.content,
          id: journalEntryData.id,
          uid: journalEntryData.uid,
          title: journalEntryData.title,
          subtitle: journalEntryData.subtitle,
          metaData: {
            createdAt: journalEntryData.metaData?.createdAt,
            updatedAt: journalEntryData.metaData?.updatedAt,
          },
        });
        if (
          journalEntryContent &&
          journalEntryData.metaData?.updatedAt &&
          new Date(journalEntryContent.updatedAt) >
            journalEntryData.metaData?.updatedAt
        ) {
          formik.setFieldValue("content", journalEntryContent.content);
        }
      }
    };
    populateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, formik.values.content]);

  return (
    <JournalEntryEditorView
      formik={formik}
      isFetching={isFetching}
      onChangeContent={onChangeContent}
      unsavedChanges={formik.dirty}
      showQuickSaveTip={!isXs}
    />
  );
};

export default JournalEntryEditorController;
