import AddGoalCard from "../add_goal_card/AddGoalCard";
import DefaultModal from "../../layout/DefaultModal";
import { useFetchGoalQuery } from "../../../api/goals";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";

const AddGoalCardModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { goalId } = useParams();
  const { data: goal } = useFetchGoalQuery(goalId, {
    skip: !goalId,
  });
  const handleGoalModalClose = () => {
    setIsOpen(false);
    navigate(-1);
  };

  useEffect(() => {
    if (goalId) {
      setIsOpen(true);
    }
  }, [goalId]);

  return (
    <DefaultModal isOpen={isOpen} onClose={handleGoalModalClose}>
      <AddGoalCard goal={goal} handleGoalModalClose={handleGoalModalClose} />
    </DefaultModal>
  );
};

export default AddGoalCardModal;
