import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import InfoSideBarController from "./info_sidebar/InfoSidebarController";
import MenuController from "./menu/MenuController";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useBreakpoints from "../../hooks/useBreakpoints";

type MenuProps = {
  leftOpen: boolean;
  rightOpen: boolean;
};

const NavBar = () => {
  const [menu, setMenu] = useState<MenuProps>({
    leftOpen: false,
    rightOpen: false,
  });

  const toggleLeftDrawer = () => {
    setMenu({
      rightOpen: false,
      leftOpen: !menu.leftOpen,
    });
  };

  const toggleRightDrawer = () => {
    setMenu({
      leftOpen: false,
      rightOpen: !menu.rightOpen,
    });
  };

  const { isXs } = useBreakpoints();

  return (
    <>
      <AppBar
        position={isXs ? "fixed" : "sticky"}
        color="inherit"
        sx={{
          top: isXs ? "auto" : 0,
          bottom: isXs ? 0 : "auto",
        }}
      >
        <MenuController open={menu.leftOpen} toggleDrawer={toggleLeftDrawer} />
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={toggleLeftDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography variant="h6" color="primary">
                  Beyond
                </Typography>
                <Typography variant="h6">Reality</Typography>
              </Box>
            </Box>
          </Box>
          <IconButton
            color="primary"
            edge="end"
            aria-label="info"
            onClick={toggleRightDrawer}
          >
            <HelpOutlineIcon />
          </IconButton>
          <InfoSideBarController
            open={menu.rightOpen}
            toggleDrawer={toggleRightDrawer}
          />
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
};

export default NavBar;
