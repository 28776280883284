import AddAPhoto from "@mui/icons-material/AddAPhoto";
import IconButton from "@mui/material/IconButton";

type AddImageButtonProps = {
  handleClick: () => void;
};

const AddImageButton: React.FC<AddImageButtonProps> = ({ handleClick }) => {
  return (
    <IconButton aria-label="add-image" onClick={handleClick} color="primary">
      <AddAPhoto />
    </IconButton>
  );
};

export default AddImageButton;
