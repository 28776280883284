import Box from "@mui/material/Box";
import PeriodicReviewButton from "../components/reviews/periodic_review_button/PeriodicReviewButton";
import PageContainer from "../components/layout/PageContainer";
import ReviewsListController from "../components/reviews/reviews_list/ReviewsListController";
import Typography from "@mui/material/Typography";

const Reviews = () => {
  return (
    <PageContainer>
      <Box
        sx={{
          height: "200px",
          width: "200px",
        }}
      >
        <PeriodicReviewButton />
      </Box>
      <Typography variant="h6" sx={{ marginY: 2 }}>
        Completed Reviews
      </Typography>
      <ReviewsListController />
    </PageContainer>
  );
};

export default Reviews;
