import AccordionDetails from "@mui/material/AccordionDetails";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { PersonalityTestType } from "@beyondrealityapp/core/personality_test/types";
import PersonalityTestForm from "./PersonalityTestForm";

type PersonalityTestDetailsProps = {
  personalityTest: PersonalityTestType;
};
const PersonalityTestDetails: React.FC<PersonalityTestDetailsProps> = ({
  personalityTest,
}) => {
  return (
    <AccordionDetails
      sx={{
        width: "100%",
        marginX: 2,
      }}
    >
      {/* TODO: Add Featuredimage with resolved CORS issue */}
      {/* <FeaturedImage url={personalityTest.link} /> */}
      {personalityTest.link ? (
        <Button
          href={personalityTest.link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="open in new tab"
          endIcon={<OpenInNewIcon />}
        >
          Open Test
        </Button>
      ) : null}
      <Alert severity="info" icon={false}>
        <AlertTitle>{personalityTest.name}</AlertTitle>
        {personalityTest.description.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </Alert>
      <PersonalityTestForm
        testName={personalityTest.name}
        testId={personalityTest.id}
        fields={personalityTest.testFields}
        uid={personalityTest.uid}
      />
    </AccordionDetails>
  );
};

export default PersonalityTestDetails;
