import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import MenuHeader from "../MenuHeader";
import MenuItem from "../MenuItem";
import { MenuItemType } from "@beyondrealityapp/core/shared/types";
import { Page } from "@beyondrealityapp/core/shared/constants";

type MenuViewProps = {
  open: boolean;
  toggleDrawer: () => void;
  checkIfActiveMenuItems: (menuItems: MenuItemType[]) => boolean;
  knowYourselfMenuItems: MenuItemType[];
  defineYourselfMenuItems: MenuItemType[];
  becomeYourselfMenuItems: MenuItemType[];
  settingsMenuItems: MenuItemType[];
  showAdminPanel: boolean;
  adminPanelMenuItems: MenuItemType[];
  logoutHandler: () => void;
  isXs: boolean;
};

const MenuView: React.FC<MenuViewProps> = ({
  open,
  toggleDrawer,
  checkIfActiveMenuItems,
  knowYourselfMenuItems,
  defineYourselfMenuItems,
  becomeYourselfMenuItems,
  settingsMenuItems,
  showAdminPanel,
  adminPanelMenuItems,
  logoutHandler,
  isXs,
}) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "space-between", sm: "flex-start  " },
          height: "100%",
        }}
      >
        {!isXs && (
          <Box>
            <MenuHeader toggleDrawer={toggleDrawer} />
            <Divider />
          </Box>
        )}
        <List>
          {checkIfActiveMenuItems(knowYourselfMenuItems) && (
            <>
              <ListSubheader>Know Yourself</ListSubheader>
              {knowYourselfMenuItems.map(
                (menuItem) =>
                  menuItem.active && (
                    <MenuItem
                      key={menuItem.text}
                      text={menuItem.text}
                      icon={menuItem.icon}
                      path={menuItem.path}
                      toggleDrawer={toggleDrawer}
                    />
                  )
              )}
            </>
          )}
          {checkIfActiveMenuItems(defineYourselfMenuItems) && (
            <>
              <Divider />
              <ListSubheader>Define yourself</ListSubheader>
              {defineYourselfMenuItems.map(
                (menuItem) =>
                  menuItem.active && (
                    <MenuItem
                      key={menuItem.text}
                      text={menuItem.text}
                      icon={menuItem.icon}
                      path={menuItem.path}
                      toggleDrawer={toggleDrawer}
                    />
                  )
              )}
            </>
          )}
          {checkIfActiveMenuItems(becomeYourselfMenuItems) && (
            <>
              <Divider />
              <ListSubheader>Become yourself</ListSubheader>
              {becomeYourselfMenuItems.map(
                (menuItem) =>
                  menuItem.active && (
                    <MenuItem
                      key={menuItem.text}
                      text={menuItem.text}
                      icon={menuItem.icon}
                      path={menuItem.path}
                      toggleDrawer={toggleDrawer}
                    />
                  )
              )}
            </>
          )}
          {checkIfActiveMenuItems(settingsMenuItems) && (
            <>
              <Divider />
              {settingsMenuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.text}
                  text={menuItem.text}
                  icon={menuItem.icon}
                  path={menuItem.path}
                  toggleDrawer={toggleDrawer}
                />
              ))}
            </>
          )}
          <Divider />
          <>
            <ListSubheader>Site controls</ListSubheader>
            {showAdminPanel &&
              adminPanelMenuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.text}
                  text={menuItem.text}
                  icon={menuItem.icon}
                  path={menuItem.path}
                  toggleDrawer={toggleDrawer}
                />
              ))}
            <MenuItem
              text="Logout"
              icon="LogoutIcon"
              path={Page.SIGN_IN}
              toggleDrawer={logoutHandler}
            />
          </>
        </List>
        {isXs && (
          <Box>
            <Divider />
            <MenuHeader toggleDrawer={toggleDrawer} />
          </Box>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default MenuView;
