import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

type DeleteButtonProps = {
  deleteHandler: () => void;
  deleting: boolean;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({
  deleteHandler,
  deleting,
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  return (
    <>
      {confirmDelete ? (
        <Box
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
          }}
        >
          <IconButton
            aria-label="delete"
            color="error"
            onClick={deleteHandler}
            disabled={deleting}
          >
            {deleting ? (
              <CircularProgress size={20} thickness={5} color="error" />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
          {!deleting ? (
            <IconButton
              aria-label="cancel"
              color="default"
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </Box>
      ) : (
        <Button
          variant="text"
          color="error"
          onClick={() => {
            setConfirmDelete(true);
          }}
        >
          Delete
        </Button>
      )}
    </>
  );
};

export default DeleteButton;
