import { auth, db } from "../config/firebase";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { JournalEntryClass } from "@beyondrealityapp/core/journal/classes";
import { JournalEntryField } from "@beyondrealityapp/core/journal/constants";
import {
  JournalEntryFirestoreType,
  JournalEntryFormType,
  JournalEntryListFilterType,
} from "@beyondrealityapp/core/journal/types";
import {
  Model,
  Operator,
  Order,
} from "@beyondrealityapp/core/shared/constants";

export const journalEntryApi = createApi({
  reducerPath: "journalEntryApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["JournalEntry"],
  endpoints: (builder) => ({
    fetchJournalEntries: builder.query({
      queryFn: async (filter: JournalEntryListFilterType) => {
        try {
          const journalEntries = await _fetchJournalEntries(filter);
          return { data: journalEntries };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["JournalEntry"],
    }),
    fetchJournalEntry: builder.query({
      queryFn: async (journalEntryId: string) => {
        try {
          const journalEntry = await _fetchJournalEntry(journalEntryId);
          return { data: journalEntry };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["JournalEntry"],
    }),
    createUpdateJournalEntry: builder.mutation({
      queryFn: async (journalEntryForm?: Partial<JournalEntryFormType>) => {
        try {
          const journalEntry = await _createUpdateJournalEntry(
            journalEntryForm
          );
          return { data: journalEntry };
        } catch (error) {
          console.log(error);
          return { error: error as Error };
        }
      },
      invalidatesTags: ["JournalEntry"],
    }),
    deleteJournalEntry: builder.mutation({
      queryFn: async (journalEntryId: string) => {
        try {
          await _deleteJournalEntry(journalEntryId);
          return { data: journalEntryId };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["JournalEntry"],
    }),
  }),
});

export const {
  useFetchJournalEntriesQuery,
  useFetchJournalEntryQuery,
  useCreateUpdateJournalEntryMutation,
  useDeleteJournalEntryMutation,
} = journalEntryApi;

const _createUpdateJournalEntry = async (
  journalEntryForm?: Partial<JournalEntryFormType>
) => {
  let journalEntry: JournalEntryClass;
  if (journalEntryForm) {
    journalEntry = JournalEntryClass.fromForm(journalEntryForm);
    journalEntry.setUpdatedTimestamp();
  } else {
    journalEntry = new JournalEntryClass({
      content: "#"
    });
    journalEntry.init(auth);
  }

  return new Promise<JournalEntryClass>((resolve, reject) => {
    setDoc(
      doc(db, Model.JOURNAL_ENTRIES, journalEntry.id),
      journalEntry.toFirestore()
    )
      .then(() => {
        resolve(journalEntry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const _deleteJournalEntry = async (journalEntryId: string) => {
  return new Promise<string>((resolve, reject) => {
    deleteDoc(doc(db, Model.JOURNAL_ENTRIES, journalEntryId))
      .then(() => {
        resolve(journalEntryId);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchJournalEntry = async (journalEntryId: string) => {
  return new Promise<JournalEntryClass>((resolve, reject) => {
    getDoc(doc(db, Model.JOURNAL_ENTRIES, journalEntryId))
      .then((doc) => {
        if (doc.exists()) {
          resolve(
            JournalEntryClass.fromFirestore({
              ...(doc.data() as JournalEntryFirestoreType),
              id: doc.id,
            })
          );
        } else {
          reject(new Error("Journal entry not found"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchJournalEntries = async (filter: JournalEntryListFilterType) => {
  const q = query(
    collection(db, Model.JOURNAL_ENTRIES),
    where(JournalEntryField.UID, Operator.EQUAL_TO, auth.currentUser?.uid),
    orderBy(JournalEntryField.CREATED_AT, Order.DESCENDING),
    limit(filter.page * 10)
  );

  return new Promise<JournalEntryClass[]>((resolve, reject) => {
    getDocs(q)
      .then((querySnapshot) => {
        const journalEntries: JournalEntryClass[] = [];
        querySnapshot.forEach((doc) => {
          journalEntries.push(
            JournalEntryClass.fromFirestore({
              ...(doc.data() as JournalEntryFirestoreType),
              id: doc.id,
            })
          );
        });
        resolve(journalEntries);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
