import AddImageButtonController from "../../image/add_image_button/AddImageButtonController";
import AddImageModalController from "../../image/add_image_modal/AddImageModalController";
import Alert from "@mui/material/Alert";
import { array, date, object, string } from "yup";
import { auth, storage } from "../../../config/firebase";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardImageHeaderController from "../../image/card_image_header/CardImageHeaderController";
import Collapse from "@mui/material/Collapse";
import { ErrorMessage, Empty } from "@beyondrealityapp/core/shared/constants";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import GoalCardAI from "./GoalCardAI";
import GoalCardChips from "./GoalCardChips";
import GoalCardContent from "./GoalCardContent";
import GoalCardDates from "./GoalCardDates";
import GoalCardDescription from "./GoalCardDescription";
import GoalCardKPI from "./GoalCardKPI";
import GoalCardThemes from "./GoalCardThemes";
import {
  GoalClass,
  GoalStatusUtility,
} from "@beyondrealityapp/core/goal/classes";
import { ImprovedGoal } from "@beyondrealityapp/core/goal/types";
import ImprovedGoalAlert from "./ImprovedGoalAlert";
import { OnlineContext } from "../../../providers/OnlineProvider";
import Snackbar from "@mui/material/Snackbar";
import SubmitDeleteActions from "../../layout/SubmitDeleteActions";
import {
  useCreateUpdateGoalMutation,
  useDeleteGoalMutation,
} from "../../../api/goals";
import { useImageUpload } from "../../../hooks/useImageUpload";
import { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";

const GoalSchema = object({
  id: string(),
  content: string().required("Please make sure to add a goal"),
  description: string(),
  kpi: string(),
  target: string(),
  status: string(),
  current: string(),
  themes: array(string()).max(10, "Max 10 themes allowed"),
  startDate: date().nullable().default(null),
  endDate: date().nullable().default(null),
});

type GoalCardProps = {
  goal?: GoalClass;
  variant?: "outlined" | "elevation";
  handleGoalModalClose?: () => void;
  onCancleClick?: () => void;
};

const AddGoalCard: React.FC<GoalCardProps> = ({
  goal,
  variant = "elevation",
  handleGoalModalClose,
  onCancleClick,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string>(Empty.STRING);
  const [improvedGoal, setImprovedGoal] = useState<ImprovedGoal>({
    name: Empty.STRING,
    reason: Empty.STRING,
  });
  const isOnline = useContext(OnlineContext);
  const [createUpdateGoal] = useCreateUpdateGoalMutation();
  const [deleteGoal] = useDeleteGoalMutation();
  const { deleteImage } = useImageUpload(storage, auth);

  const initialValues: Partial<GoalFormType> = {
    id: Empty.STRING,
    uid: Empty.STRING,
    emoji: Empty.STRING,
    content: Empty.STRING,
    image: Empty.STRING,
    imageVerticalOffset: 50,
    description: Empty.STRING,
    status: Empty.STRING,
    kpi: Empty.STRING,
    target: Empty.STRING,
    current: Empty.STRING,
    startDate: null,
    endDate: null,
    themes: [],
    history: [],
  };

  const submitHandler = async () => {
    if (!isOnline) {
      setError(ErrorMessage.OFFLINE);
      return;
    }
    setError(Empty.STRING);
    setSubmitting(true);
    createUpdateGoal(formik.values)
      .unwrap()
      .then(() => {
        formik.resetForm();
        setDescriptionOpen(false);
        setKpiOpen(false);
        setThemesOpen(false);
        setDatesOpen(false);
        setGoalCreatedSnackbar(true);
        if (handleGoalModalClose) {
          handleGoalModalClose();
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: GoalSchema,
    onSubmit: submitHandler,
    validateOnChange: false,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (goal) {
      formik.setValues(goal.toForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal]);

  useEffect(() => {
    if (formik.values.description) {
      setDescriptionOpen(true);
    }
    if (formik.values.target || formik.values.current || formik.values.kpi) {
      setKpiOpen(true);
    }
    if (formik.values.themes && formik.values.themes.length > 0) {
      setThemesOpen(true);
    }
    if (formik.values.startDate || formik.values.endDate) {
      setDatesOpen(true);
    }
    if (goal?.id) {
      setAllowDelete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (!formik.values.target || !formik.values.current) return;
    const startDate = formik.values.startDate
      ? formik.values.startDate.toDate()
      : null;
    const endDate = formik.values.endDate
      ? formik.values.endDate.toDate()
      : null;

    formik.setFieldValue(
      "status",
      GoalStatusUtility.getStatusBasedOnTargetCurrentAndDates(
        formik.values.target,
        formik.values.current,
        startDate,
        endDate
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.target,
    formik.values.current,
    formik.values.startDate,
    formik.values.endDate,
    formik.dirty,
  ]);

  const setImage = (image: string) => {
    formik.setFieldValue("image", image);
  };

  const [allowDelete, setAllowDelete] = useState<boolean>(false);

  const [goalCreatedSnackbar, setGoalCreatedSnackbar] =
    useState<boolean>(false);

  const [imagePickerOpen, setImagePickerOpen] = useState<boolean>(false);
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const handleDescriptionToggle = () => {
    setDescriptionOpen((prevState) => !prevState);
  };
  const [kpiOpen, setKpiOpen] = useState<boolean>(false);
  const handleKpiToggle = () => {
    setKpiOpen((prevState) => !prevState);
  };
  const [themesOpen, setThemesOpen] = useState<boolean>(false);
  const handleThemesToggle = () => {
    setThemesOpen((prevState) => !prevState);
  };
  const [datesOpen, setDatesOpen] = useState<boolean>(false);
  const handleDatesToggle = () => {
    setDatesOpen((prevState) => !prevState);
  };
  const offsetHandler = (offset: number) => {
    formik.setFieldValue("imageVerticalOffset", offset);
  };

  const cancelHandler = () => {
    formik.resetForm();
    setDescriptionOpen(false);
    setKpiOpen(false);
    setThemesOpen(false);
    setDatesOpen(false);
    if (handleGoalModalClose) {
      handleGoalModalClose();
    }
    if (onCancleClick) {
      onCancleClick();
    }
  };

  const deleteHandler = () => {
    if (!formik.values.id) {
      setError(ErrorMessage.NO_ID);
      return;
    }
    if (!isOnline) {
      setError(ErrorMessage.OFFLINE);
      return;
    }
    setError(Empty.STRING);
    setDeleting(true);
    deleteGoal({
      goalId: formik.values.id,
      parentGoalId: formik.values.parent || Empty.STRING,
    })
      .unwrap()
      .then(() => {
        if (handleGoalModalClose) {
          handleGoalModalClose();
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const imageDeleteHandler = () => {
    const uid = auth.currentUser?.uid ?? "";
    if (uid && formik.values.image?.includes(uid)) {
      deleteImage(formik.values.image)
        .then(() => {
          formik.setFieldValue("image", Empty.STRING);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      formik.setFieldValue("image", Empty.STRING);
    }
  };

  return (
    <>
      <Card variant={variant}>
        {formik.values.image ? (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <CardImageHeaderController
              imageUrl={formik.values.image}
              text={formik.values.status}
              imageDeleteHandler={imageDeleteHandler}
              offSetHandler={offsetHandler}
              verticalOffset={formik.values.imageVerticalOffset || 50}
            />
          </Box>
        ) : null}
        <Box
          onSubmit={formik.handleSubmit}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent
            sx={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
          >
            <Box
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-end",
                display: "flex",
                flex: 1,
              }}
            >
              <GoalCardContent formik={formik} />
              <AddImageButtonController
                handleClick={() => setImagePickerOpen(true)}
              />
            </Box>
            <ImprovedGoalAlert
              formik={formik}
              improvedGoal={improvedGoal}
              setImprovedGoal={setImprovedGoal}
            />
            <GoalCardAI formik={formik} setImprovedGoal={setImprovedGoal} />
            <GoalCardChips
              descriptionOpen={descriptionOpen}
              kpiOpen={kpiOpen}
              themesOpen={themesOpen}
              datesOpen={datesOpen}
              handleDescriptionToggle={handleDescriptionToggle}
              handleKpiToggle={handleKpiToggle}
              handleThemesToggle={handleThemesToggle}
              handleDatesToggle={handleDatesToggle}
              formik={formik}
            />
            <GoalCardDescription formik={formik} collapsed={descriptionOpen} />
            <GoalCardKPI formik={formik} collapsed={kpiOpen} />
            <GoalCardThemes formik={formik} collapsed={themesOpen} />
            <GoalCardDates formik={formik} collapsed={datesOpen} />
          </CardContent>
          <CardActions>
            <SubmitDeleteActions
              cancelHandler={cancelHandler}
              deleteHandler={deleteHandler}
              allowDelete={allowDelete}
              submitting={submitting}
              deleting={deleting}
            />
          </CardActions>
          <Collapse in={error !== Empty.STRING}>
            <Alert severity="error" sx={{ margin: 2 }}>
              {error}
            </Alert>
          </Collapse>
        </Box>
      </Card>
      <Snackbar
        open={goalCreatedSnackbar}
        autoHideDuration={6000}
        onClose={() => setGoalCreatedSnackbar(false)}
        message="Goal created 🎉"
      />
      {/* TODO: Only render the AddImageModalController if the imagePickerOpen is true */}
      <AddImageModalController
        setImageHandler={setImage}
        open={imagePickerOpen}
        onClose={() => setImagePickerOpen(false)}
      />
    </>
  );
};
export default AddGoalCard;
