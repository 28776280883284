import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFetchThemesQuery } from "../../../api/theme";
import ThemesListView from "./ThemesListView";

const ThemesListController = () => {
  const dispatch = useDispatch();
  const {
    data: themes = [],
    isLoading,
    isError,
    error,
  } = useFetchThemesQuery(undefined);

  useEffect(() => {
    if (isError) {
      dispatch(
        showSnackbar({
          message: error as string,
          severity: Severity.ERROR,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <ThemesListView
      themes={themes}
      loading={isLoading}
      error={isError}
      errorMessage={error as string}
    />
  );
};

export default ThemesListController;
