import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import GoalCardController from "../goal_card/GoalCardController";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";
import { GoalFilterType } from "@beyondrealityapp/core/goal/types";
import List from "@mui/material/List";
import ListFilterController from "../list_filter/ListFilterController";
import ListItem from "@mui/material/ListItem";
import LoadingSpinner from "../../layout/LoadingSpinner";
import NoResults from "../../layout/NoResults";
import Snackbar from "@mui/material/Snackbar";
import { TransitionGroup } from "react-transition-group";

type GoalsListViewProps = {
  goals: GoalClass[] | undefined;
  isError?: boolean;
  error?: unknown;
  loading: boolean;
  loadingMore: boolean;
  hideFilter?: boolean;
  filter: GoalFilterType;
  setFilter: (filter: GoalFilterType) => void;
  compact?: boolean;
  noResultsMessage?: string;
};

const GoalsListView: React.FC<GoalsListViewProps> = ({
  goals,
  isError,
  error,
  loading,
  hideFilter,
  filter,
  setFilter,
  compact,
  noResultsMessage = "Your filter doesn't match any goals. Try changing your filters by clicking on 'Open Filters'",
}) => {
  let errorMessage = "An error occurred while fetching goals";
  if (error instanceof Error) {
    errorMessage = "⚠️ An error occurred: " + error.message;
  }

  return (
    <>
      <List
        dense
        sx={{
          width: "100%",
        }}
      >
        {!hideFilter && (
          <ListItem disableGutters>
            <ListFilterController filter={filter} setFilter={setFilter} />
          </ListItem>
        )}
        {loading && (
          <ListItem key="loading-spinner">
            <LoadingSpinner />
          </ListItem>
        )}
        {!loading && goals?.length !== 0 && (
          <TransitionGroup>
            {goals?.map((goal) => (
              <Collapse key={goal.id}>
                <ListItem key={goal.id} disableGutters>
                  <GoalCardController goal={goal} compact={compact} />
                </ListItem>
              </Collapse>
            ))}
          </TransitionGroup>
        )}
      </List>
      {!loading && goals?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100px",
          }}
        >
          <NoResults message={noResultsMessage} />
        </Box>
      )}
      <Snackbar open={isError} message={errorMessage} />
    </>
  );
};

export default GoalsListView;
