import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FormikState, FormikHelpers } from "formik";
import { functions } from "../../../config/firebase";
import { GoalFormType } from "@beyondrealityapp/core/goal/types";
import Grid from "@mui/material/Grid";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import { ImprovedGoal } from "@beyondrealityapp/core/goal/types";
import { useState, useEffect } from "react";

type improvedGoalAIRequest = {
  goalContent?: string;
  language?: string;
  wake?: boolean;
};

type improvedGoalAIResponse = {
  data: {
    content: string;
    reason: string;
  };
};

type GoalCardAIProps = {
  formik: FormikState<Partial<GoalFormType>> & FormikHelpers<Partial<GoalFormType>>;
  setImprovedGoal: (improvedGoal: ImprovedGoal) => void;
};

const GoalCardAI: React.FC<GoalCardAIProps> = ({
  formik,
  setImprovedGoal,
}) => {
  const [functionIsAwake, setFunctionIsAwake] = useState<boolean>(false);
  const [isLoadingImprovedGoal, setIsLoadingImprovedGoal] =
    useState<boolean>(false);
  const [isLoadingSuggestKPI, setIsLoadingSuggestKPI] =
    useState<boolean>(false);
  const [isLoadingProposeSubGoal, setIsLoadingProposeSubGoal] =
    useState<boolean>(false);

  const improveGoal: HttpsCallable<improvedGoalAIRequest> = httpsCallable(
    functions,
    "improveGoal"
  );

  useEffect(() => {
    improveGoal({ wake: true })
      .then(() => {
        setFunctionIsAwake(true);
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const improveGoalHandler = async () => {
    if (formik.values.content === "") {
      setImprovedGoal({
        name: "Please enter a goal first",
        reason: "Use the AI to improve your goal",
      });
      return;
    }
    setIsLoadingImprovedGoal(true);
    improveGoal({ goalContent: formik.values.content })
      .then((result: improvedGoalAIResponse) => {
        setImprovedGoal({
          name: result.data.content,
          reason: result.data.reason,
        });
        setIsLoadingImprovedGoal(false);
      })
      .catch((error) => {
        setImprovedGoal({
          name: "Something went wrong, please try again",
          reason: "Use the AI to improve your goal",
        });
        console.log(error);
        setIsLoadingImprovedGoal(false);
      });
  };
  const suggestKPIHandler = () => {
    setIsLoadingSuggestKPI(true);
    // To-do: add AI capability for suggesting a KPI
    setIsLoadingSuggestKPI(false);
  };

  const suggestSubGoalHandler = () => {
    setIsLoadingProposeSubGoal(true);
    // To-do: add AI capability for suggesting a subgoal
    setIsLoadingProposeSubGoal(false);
  };

  return (
    <Grid container columnSpacing={1} marginTop={1}>
      <Grid item>
        <Button
          size="small"
          variant="text"
          onClick={improveGoalHandler}
          disabled={!functionIsAwake}
          startIcon={
            isLoadingImprovedGoal ? <CircularProgress size={16} /> : null
          }
        >
          Improve Goal
        </Button>
      </Grid>
      {false ? (
        <>
          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={suggestKPIHandler}
              startIcon={
                isLoadingSuggestKPI ? <CircularProgress size={16} /> : null
              }
            >
              Suggest KPI
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={suggestSubGoalHandler}
              startIcon={
                isLoadingProposeSubGoal ? <CircularProgress size={16} /> : null
              }
            >
              Propose subgoal
            </Button>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default GoalCardAI;
